<template>
  <div
    style="height: 100vh; background-color: white; color: black"
    class="p-0 d-flex flex-column justify-content-around"
  >
    <div class="w-100" style="text-align: center">Unity</div>
  </div>
</template>

<script>
export default {
  components: {},
  props: { user: { type: Object, required: true } },
  data() {
    return {
      // Actual Data
      // ...
      // Making global consts and functions available to THIS
    };
  },
  computed: {},
  created() {
    this.$emit("checkUserGivenEnoughInfo");
    this.goToUserTypeHomePage();
  },
  beforeUnmount() {},
  methods: {
    goToUserTypeHomePage() {
      // // Step 1. If you have chosen before, and are still eligible, go back there:
      // const lastHomePage =
      //   this.user.contactObj && this.user.contactObj.lastHomePage;
      // if (lastHomePage === "Researcher" && this.user.privilegesObj.researcher) {
      //   this.$router.replace({ name: "Researcher" });
      // } else if (lastHomePage === "Manage" && this.user.privilegesObj.manager) {
      //   this.$router.replace({ name: "manage" });
      // } else if (lastHomePage === "Hq" && this.user.privilegesObj.hq) {
      //   this.$router.replace({ name: "Hq" });
      // } else if (lastHomePage === "ProjectClassDispatcher" && true) {
      //   this.$router.replace({ name: "ProjectClassDispatcher" });
      // }

      // Step 2. If you have NOT chosen before, then go to them in this order of priority
      //else
      if (this.user.privilegesObj.researcher) {
        this.$router.replace({ name: "Researcher" });
      } else if (this.user.privilegesObj.manager) {
        this.$router.replace({ name: "Manage" });
      } else {
        this.$router.replace({
          name: "ProjectClassDispatcherWithoutView",
          params: { view: null },
        });
      }
    },

    //
    //  ______ _          _
    //  |  ___(_)        | |
    //  | |_   _ _ __ ___| |__   __ _ ___  ___
    //  |  _| | | '__/ _ \ '_ \ / _` / __|/ _ \
    //  | |   | | | |  __/ |_) | (_| \__ \  __/
    //  \_|   |_|_|  \___|_.__/ \__,_|___/\___|
    //

    //
    //   _   _                            _   _
    //  | | | |                          | | (_)
    //  | | | |___  ___ _ __    __ _  ___| |_ _  ___  _ __
    //  | | | / __|/ _ \ '__|  / _` |/ __| __| |/ _ \| '_ \
    //  | |_| \__ \  __/ |    | (_| | (__| |_| | (_) | | | |
    //   \___/|___/\___|_|     \__,_|\___|\__|_|\___/|_| |_|
    //

    //
    //  ______                         _
    //  |  ___|                       | |
    //  | |_ ___  _ __ _ __ ___   __ _| |_
    //  |  _/ _ \| '__| '_ ` _ \ / _` | __|
    //  | || (_) | |  | | | | | | (_| | |_
    //  \_| \___/|_|  |_| |_| |_|\__,_|\__|
    //

    //   _   _             _             _   _
    //  | \ | |           (_)           | | (_)
    //  |  \| | __ ___   ___  __ _  __ _| |_ _  ___  _ __
    //  | . ` |/ _` \ \ / / |/ _` |/ _` | __| |/ _ \| '_ \
    //  | |\  | (_| |\ V /| | (_| | (_| | |_| | (_) | | | |
    //  \_| \_/\__,_| \_/ |_|\__, |\__,_|\__|_|\___/|_| |_|
    //                        __/ |
    //                       |___/

    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped></style>
