<template>
  <transition name="slow-to-anger">
    <div class="alert alert-danger m-1" style="z-index: 1000">
      <!-- This is deliberately NOT full width, so that users can still reach the "LOCATION" and "USER NAME" areas behind the top -->

      <h4>
        Check internet connection
        <b-button
          variant="link"
          size="sm"
          @click="showMoreHelp = !showMoreHelp"
          >{{ !showMoreHelp ? "Help" : "Close help" }}</b-button
        >
      </h4>
      <transition name="swish">
        <h5 v-if="showMoreHelp">
          <p>Can you improve internet reception?</p>
          <p>
            If you are using Hospital Wifi, beware that many hospitals attempt
            to intrude on your encrypted communications, so are incompatible
            with this system. You might have more success switching to mobile
            data rather than Wifi.
          </p>
        </h5>
      </transition>
    </div>
  </transition>
</template>

<script>
export default {
  data: function () {
    return { showMoreHelp: false };
  },
};
</script>

<style scoped>
/* Slow-appearing warning message, quick-vanishing*/

.slow-to-anger-enter,
.slow-to-anger-leave-to {
  opacity: 0;
  max-height: 0;
  overflow-y: scroll;
}

.slow-to-anger-enter-active {
  transition: opacity 10s ease-in-out 20s, max-height 10s ease-in-out 20s;
}

.slow-to-anger-leave-active {
  transition: opacity 2s, max-height 2s linear;
}

.slow-to-anger-enter-to,
.slow-to-anger-leave {
  opacity: 1;
  max-height: 100vh;
}
</style>