import Vue from "vue";

import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";

import BootstrapVue from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.css";
Vue.use(BootstrapVue);

//github.com/rigor789/vue-scrollto
import VueScrollTo from "vue-scrollto";
Vue.use(VueScrollTo);

// https://vuefire.vuejs.org/vuefire/getting-started.html#plugin
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
import { rtdbPlugin } from "vuefire";
Vue.use(rtdbPlugin);

// https://sentry.io/onboarding/darrel-francis/scantensus/configure/javascript-vue/
const SEND_ERRORS_ONLY_TO_SENTRY = false;
if (SEND_ERRORS_ONLY_TO_SENTRY) {
  Sentry.init({
    dsn: "https://cd7a10d3691142c4b98b131ae08b7905@sentry.io/1507184",
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],
  });
}

// https://morioh.com/p/9e710eb15d83
import VueCompositionApi from "@vue/composition-api";
Vue.use(VueCompositionApi);

// Pinia: https://pinia.vuejs.org/getting-started.html
import { createPinia, PiniaVuePlugin } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

Vue.use(PiniaVuePlugin);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

Vue.config.productionTip = false;

new Vue({
  router,
  pinia,

  render: h => h(App),
}).$mount("#app");
