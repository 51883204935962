<template>
  <div class="mx-4">
    <div class="d-flex justify-content-between">
      <div id="text-bit">
        <h3>
          Click
          <b-button variant="dark"><strong>&vellip;</strong></b-button> icon
          in<br />
          corner of screen
        </h3>
      </div>
      <div
        id="arrow-bit"
        style="font-size: 300%; font-weight: 700; color: lightgray"
      >
        ⭜
      </div>
    </div>

    <div>
      <b-button v-b-toggle.collapse-browser-menu variant="link" class="p-0"
        >I don't have this icon</b-button
      >
      <b-collapse id="collapse-browser-menu">
        <b-card style="max-width: 40em">
          <p>
            <ol>
              <li>Check you are using Google Chrome.</li>
              <br />
              <li>
                If that corner contains a
                <b-button variant="dark" size="sm"><strong>⇧</strong></b-button>
                icon instead, click it to update Chrome.
              </li>
              <br />
              <li>
                Tell the app team your problem is "<code
                  >No triple-dot icon in {{ device.browser.name }}
                  {{ device.browser.version }} on {{ device.os.name }}
                  {{ device.os.version }}</code
                >".
              </li>
            </ol>
          </p>
          <b-button v-b-toggle.collapse-browser-menu variant="light"
            >Close</b-button
          >
        </b-card>
      </b-collapse>
    </div>

    <br />
    <h3>Click "Install" or "Open" or "Add to Home Screen"</h3>
    <div>
      <b-button
        v-b-toggle.collapse-chrome-install-or-open
        variant="link"
        class="p-0"
        >Neither option is on menu</b-button
      >
      <b-collapse id="collapse-chrome-install-or-open">
        <b-card style="max-width: 40em">
          <p>
            <ul>
              <li>
                If the top option in the menu is "Update Chrome", please do so.
              </li>
              <br />

              <li>
                Tell the app team your problem is "<code
                  >No <em>Install</em> or <em>Open</em> item in triple-dot menu
                  of Chrome version {{ device.browser.version }} on
                  {{ device.os.name }} {{ device.os.version }}</code
                >".
              </li>
              <br />
            </ul>
          </p>
          <b-button v-b-toggle.collapse-chrome-install-or-open variant="light"
            >Close</b-button
          >
        </b-card>
      </b-collapse>
    </div>
    <br />
    <h3>Return to your home screen and open app.</h3>
 
    <br />
  </div>
</template>

<script>
export default {
  props: {
    device: { type: Object, required: true },
    deviceToPromptInstallation: { type: String, required: true },
  },
};
</script>

<style>
</style>