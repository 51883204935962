<template>
  <svg width="0" height="0" style="position: absolute">
    <!--

Originally at https://rastasheep.github.io/ionicons-sprite/ but now gone.
Now choose your icon at:

And then download the "all-inclusive" SVG at https://github.com/rastasheep/ionicons-sprite/releases/download/v0.0.1/sprite.svg
and copy your chosen icon


    EXAMPLE OF USE

SMALL THING, e.g. added to button
  <svg class="ion">
    <use xlink:href="#ion-chevron-left">
    </use>
  </svg>




BIG THING
  <svg class="ion" style="height:52px;width:52px;">
    <use xlink:href="#ion-ios-list-outline">
    </use>
  </svg>

    -->
    <defs>
      <!--
   ______                _
   | ___ \              | |
   | |_/ /__ _ _ __   __| | ___  _ __ ___    ___  ___  _   _ _ __ ___ ___  ___
   |    // _` | '_ \ / _` |/ _ \| '_ ` _ \  / __|/ _ \| | | | '__/ __/ _ \/ __|
   | |\ \ (_| | | | | (_| | (_) | | | | | | \__ \ (_) | |_| | | | (_|  __/\__ \
   \_| \_\__,_|_| |_|\__,_|\___/|_| |_| |_| |___/\___/ \__,_|_|  \___\___||___/
      -->
      <symbol id="pencil">
        <svg viewBox="0 0 283.093 283.093">
          <g>
            <path
              d="M271,54L219,2.2c-2.93-2.928-7.678-2.928-10.607,0L78.274,132.303c-1.049,1.05-1.764,2.388-2.053,3.843
		l-12.964,65.29c-0.487,2.456,0.282,4.994,2.053,6.765c1.421,1.42,3.334,2.196,5.304,2.196c0.485,0,0.975-0.047,1.461-0.144
		l65.29-12.964c1.456-0.289,2.793-1.004,3.843-2.053L271.315,65.129C274.244,62.2,274.244,57.452,271.315,54.522z M83.182,178.114
		l6.776-34.127l39.566,39.566l-34.127,6.776L83.182,178.114z"
            />
            <path
              d="M205.912,227.066c-71.729-30.029-118.425,19.633-132.371,27.175c-17.827,9.641-42.941,20.97-48.779,1.358
		c-3.522-11.832,15.521-24.479,28.131-28.42c9.2-2.876,5.271-17.358-3.988-14.465c-19.582,6.121-42.948,22.616-38.851,45.839
		c3.044,17.256,24.67,32.995,66.368,11.114c30.308-15.902,50.897-48.84,114.733-31.783c20.969,5.602,37.92,19.27,45.178,40.057
		c3.168,9.07,17.662,5.169,14.465-3.988C243.062,251.799,227.411,236.067,205.912,227.066z"
            />
          </g>
        </svg>
      </symbol>

      <symbol id="freehand-plain">
        <svg viewBox="40 85 92 92">
          <path
            fill="none"
            stroke-width="5"
            d="m 54.091354,137.00451 c -18.13103,21.77278 32.02237,30.73517 23.69271,4.59771 -8.32966,-26.13746 31.828886,-10.28879 22.606156,-35.69136 -9.222726,-25.402572 30.45979,-21.674542 29.40471,1.25045 -1.05508,22.92499 -21.43806,32.06906 -32.735736,47.48632 -11.29768,15.41726 1.23744,26.2204 12.887126,17.1456 11.64969,-9.0748 21.392,-13.7138 17.09436,-25.33967"
          />
        </svg>
      </symbol>

      <symbol id="freehand-minus-1">
        <svg viewBox="40 85 92 92">
          <path
            fill="none"
            stroke-width="2.5"
            stroke="var(--danger)"
            d="m 54.091354,137.00451 c -18.13103,21.77278 32.02237,30.73517 23.69271,4.59771 -8.32966,-26.13746 31.828886,-10.28879 22.606156,-35.69136 -9.222726,-25.402572 30.45979,-21.674542 29.40471,1.25045 -1.05508,22.92499 -21.43806,32.06906 -32.735736,47.48632 -11.29768,15.41726 1.23744,26.2204 12.887126,17.1456 11.64969,-9.0748 21.392,-13.7138 17.09436,-25.33967"
          />
          <path
            fill="none"
            stroke-width="5"
            stroke="white"
            d="m 54.091354,137.00451 c -18.13103,21.77278 32.02237,30.73517 23.69271,4.59771 -8.32966,-26.13746 31.828886,-10.28879 22.606156,-35.69136 -9.222726,-25.402572 30.45979,-21.674542 29.40471,1.25045 -1.05508,22.92499 -21.43806,32.06906 -32.735736,47.48632 -11.29768,15.41726 1.23744,26.2204 12.887126,17.1456 "
          />
        </svg>
      </symbol>

      <symbol id="freehand-minus-10">
        <svg viewBox="40 85 92 92">
          <path
            fill="none"
            stroke-width="2.5"
            stroke="var(--danger)"
            d="m 54.091354,137.00451 c -18.13103,21.77278 32.02237,30.73517 23.69271,4.59771 -8.32966,-26.13746 31.828886,-10.28879 22.606156,-35.69136 -9.222726,-25.402572 30.45979,-21.674542 29.40471,1.25045 -1.05508,22.92499 -21.43806,32.06906 -32.735736,47.48632 -11.29768,15.41726 1.23744,26.2204 12.887126,17.1456 11.64969,-9.0748 21.392,-13.7138 17.09436,-25.33967"
          />
          <path
            fill="none"
            stroke-width="5"
            stroke="white"
            d="m 54.091354,137.00451 c -18.13103,21.77278 32.02237,30.73517 23.69271,4.59771 -8.32966,-26.13746 31.828886,-10.28879 22.606156,-35.69136 -9.222726,-25.402572 30.45979,-21.674542 29.40471,1.25045 -1.05508,22.92499 -21.43806,32.06906 -32.735736,47.48632"
          />
        </svg>
      </symbol>

      <symbol id="freehand-minus-100">
        <svg viewBox="40 85 92 92">
          <path
            fill="none"
            stroke-width="2.5"
            stroke="var(--danger)"
            d="m 54.091354,137.00451 c -18.13103,21.77278 32.02237,30.73517 23.69271,4.59771 -8.32966,-26.13746 31.828886,-10.28879 22.606156,-35.69136 -9.222726,-25.402572 30.45979,-21.674542 29.40471,1.25045 -1.05508,22.92499 -21.43806,32.06906 -32.735736,47.48632 -11.29768,15.41726 1.23744,26.2204 12.887126,17.1456 11.64969,-9.0748 21.392,-13.7138 17.09436,-25.33967"
          />
          <path
            fill="none"
            stroke-width="5"
            stroke="white"
            d="m 54.091354,137.00451 c -18.13103,21.77278 32.02237,30.73517 23.69271,4.59771 -8.32966,-26.13746 31.828886,-10.28879 22.606156,-35.69136"
          />
        </svg>
      </symbol>

      <symbol id="freehand-accept">
        <svg viewBox="40 85 92 92">
          <path
            fill="none"
            stroke-width="5"
            d="m 54.091354,137.00451 c -18.13103,21.77278 32.02237,30.73517 23.69271,4.59771 -8.32966,-26.13746 31.828886,-10.28879 22.606156,-35.69136 -9.222726,-25.402572 30.45979,-21.674542 29.40471,1.25045 -1.05508,22.92499 -21.43806,32.06906 -32.735736,47.48632 -11.29768,15.41726 1.23744,26.2204 12.887126,17.1456 11.64969,-9.0748 21.392,-13.7138 17.09436,-25.33967"
          />
          <path
            fill="none"
            stroke-width="20"
            stroke="#40ff4080"
            d="M 40,85 m 20,60 l 20,20 45,-45"
          />
        </svg>
      </symbol>

      <symbol id="freehand-reject">
        <svg viewBox="40 85 92 92">
          <path
            fill="none"
            stroke-width="2.5"
            stroke="white"
            d="m 54.091354,137.00451 c -18.13103,21.77278 32.02237,30.73517 23.69271,4.59771 -8.32966,-26.13746 31.828886,-10.28879 22.606156,-35.69136 -9.222726,-25.402572 30.45979,-21.674542 29.40471,1.25045 -1.05508,22.92499 -21.43806,32.06906 -32.735736,47.48632 -11.29768,15.41726 1.23744,26.2204 12.887126,17.1456 11.64969,-9.0748 21.392,-13.7138 17.09436,-25.33967 "
          />
          <path
            fill="none"
            stroke-width="20"
            stroke="#FF4040c0"
            d="M 40,85 m 30,20 l 50,50 m 0,-50 l -50,50"
          />
        </svg>
      </symbol>

      <symbol id="make-curve-closed">
        <svg viewBox="-110 -110 220 220">
          <path
            fill="none"
            stroke-width="10"
            stroke="white"
            d="M -50,-100 C -100,-75 -100,100 0,100  100,100 100,-75 50,-100"
          />
          <path
            fill="none"
            stroke-width="5"
            stroke="#e0b040"
            d="M 50,-100 L -50,-100"
          />
        </svg>
      </symbol>

      <symbol id="make-curve-open">
        <svg viewBox="-110 -110 220 220">
          <path
            fill="none"
            stroke-width="10"
            stroke="white"
            d="M -50,-100 C -100,-75 -100,100 0,100  100,100 100,-75 50,-100"
          />
        </svg>
      </symbol>

      <symbol id="swap">
        <svg viewBox="0 0 512 512">
          <path
            d="M388.9 266.3c-5.1-5-5.2-13.3-.1-18.4L436 200H211c-7.2 0-13-5.8-13-13s5.8-13 13-13h224.9l-47.2-47.9c-5-5.1-5-13.3.1-18.4 5.1-5 13.3-5 18.4.1l69 70c1.1 1.2 2.1 2.5 2.7 4.1.7 1.6 1 3.3 1 5 0 3.4-1.3 6.6-3.7 9.1l-69 70c-5 5.2-13.2 5.3-18.3.3zM123.1 404.3c5.1-5 5.2-13.3.1-18.4L76.1 338H301c7.2 0 13-5.8 13-13s-5.8-13-13-13H76.1l47.2-47.9c5-5.1 5-13.3-.1-18.4-5.1-5-13.3-5-18.4.1l-69 70c-1.1 1.2-2.1 2.5-2.7 4.1-.7 1.6-1 3.3-1 5 0 3.4 1.3 6.6 3.7 9.1l69 70c5 5.2 13.2 5.3 18.3.3z"
          />
        </svg>
      </symbol>

      <symbol id="blindfold">
        <!-- Paid for license for this from NOUN project noun_blindfold_16063.svg 9.8.19 -->
        <svg fill="#ffffff" viewBox="0 0 100 100">
          <g>
            <path
              d="M85.559,23.946C80.234,9.938,66.697-0.016,50.818-0.016c-15.789,0-30.425,11.102-34.877,23.962H85.559z"
            />
            <polygon
              fill="#000000c0"
              points="7.416,43.709 91.722,43.709 91.722,26.664 7.416,26.664  "
            />
            <path
              fill="#000000c0"
              d="M89.438,46.298c-0.382,2.991-1.722,9.102-6.412,15.13c-1.669,2.149-3.185,3.771-4.469,4.993v17.273l10.645-14.268   l8.944,2.038L89.438,46.298z"
            />
            <path
              d="M68.507,46.427c0.819,2.223,0.802,4.97-0.082,8.047c-1.441,5.03-5.649,8.404-10.006,8.404   c-0.767,0-1.538-0.104-2.299-0.321c-0.721-0.206-1.139-0.958-0.932-1.68c0.205-0.721,0.956-1.142,1.68-0.932   c3.644,1.035,7.663-1.747,8.945-6.222c0.589-2.054,1.029-4.965-0.218-7.173c-0.026-0.045-0.063-0.08-0.091-0.124H9.284   L4.098,56.695c-0.155,0.349-0.229,0.75-0.229,1.161c0,1.652,1.332,2.993,2.99,2.993h6.785v11.038   c0,8.635,3.765,14.524,14.646,14.524h2.579V100h42.898l-0.025-33.126c1.136-0.764,3.794-2.815,7.138-7.115   c4.047-5.201,5.361-10.517,5.791-13.332l0,0H68.507z"
            />
          </g>
        </svg>
      </symbol>

      <symbol id="curve-quick-accept">
        <svg viewBox="0 0 34 34">
          <g transform="translate(-39.327328,-76.451514)">
            <path
              style="fill: none; stroke: #ffffff; stroke-width: 0.3"
              d="m 40.164582,89.701209 c 4.56197,-8.180085 9.999282,-9.837512 16.309463,-9.630764 3.997488,0.130975 8.154937,3.170542 9.910489,6.7643 2.19086,4.484871 1.596696,10.647928 -0.943856,14.944385 -2.339711,3.9568 -7.048487,7.59447 -11.640889,7.39354 -5.343663,-0.2338 -12.742057,-9.753184 -12.742057,-9.753184"
            />
            <circle
              style="fill: none; stroke: #ffffff; stroke-width: 0.3"
              cx="40.16"
              cy="90"
              r="0.8"
            />
            <circle
              style="fill: none; stroke: #ffffff; stroke-width: 0.3"
              cx="41.06"
              cy="99"
              r="0.8"
            />
            <path
              d="m 57.839058,76.515384 c -0.856267,0.59034 -1.722505,1.38013 -2.622649,2.3893 -0.980586,1.10091 -2.011697,2.51495 -2.868629,3.93431 -0.04787,0.0791 -0.07645,0.11966 -0.08177,0.11767 -0.0053,-0.002 -0.475998,-0.31512 -1.047064,-0.69672 -0.571067,-0.38159 -1.042413,-0.69605 -1.047732,-0.69937 -0.0073,-0.004 -0.0066,-0.001 0.0013,0.0106 0.06515,0.0931 2.462433,3.37588 2.465756,3.37654 0.002,0 0.0226,-0.0439 0.04521,-0.0984 0.814385,-1.9399 1.610155,-3.47958 2.551517,-4.9375 0.763195,-1.18135 1.649378,-2.32482 2.59207,-3.34263 0.0605,-0.0645 0.108363,-0.11767 0.107033,-0.11767 -0.0013,0 -0.04454,0.0286 -0.09507,0.0638 z"
              style="fill: #00ff00; stroke: none"
            />
            <path
              d="m 54.828399,101.9279 c -0.856267,0.59034 -1.722505,1.38013 -2.622649,2.3893 -0.980586,1.10091 -2.011697,2.51495 -2.868629,3.93431 -0.04787,0.0791 -0.07645,0.11966 -0.08177,0.11767 -0.0053,-0.002 -0.475998,-0.31512 -1.047064,-0.69672 -0.571067,-0.38159 -1.042413,-0.69605 -1.047732,-0.69937 -0.0073,-0.004 -0.0066,-0.001 0.0013,0.0106 0.06515,0.0931 2.462433,3.37588 2.465756,3.37654 0.002,0 0.0226,-0.0439 0.04521,-0.0984 0.814385,-1.9399 1.610155,-3.47958 2.551517,-4.9375 0.763195,-1.18135 1.649378,-2.32482 2.59207,-3.34263 0.0605,-0.0645 0.108363,-0.11767 0.107033,-0.11767 -0.0013,0 -0.04454,0.0286 -0.09507,0.0638 z"
              style="fill: #00ff00; stroke: none"
            />
            <path
              d="m 70.01615,82.178589 c -0.856267,0.59034 -1.722505,1.38013 -2.622649,2.3893 -0.980586,1.10091 -2.011697,2.514948 -2.868629,3.934308 -0.04787,0.0791 -0.07645,0.11966 -0.08177,0.11767 -0.0053,-0.002 -0.475998,-0.31512 -1.047064,-0.69672 -0.571067,-0.38159 -1.042413,-0.69605 -1.047732,-0.69937 -0.0073,-0.004 -0.0066,-0.001 0.0013,0.0106 0.06515,0.0931 2.462433,3.37588 2.465756,3.37654 0.002,0 0.0226,-0.0439 0.04521,-0.0984 0.814385,-1.9399 1.610155,-3.47958 2.551517,-4.937498 0.763195,-1.18135 1.649378,-2.32482 2.59207,-3.34263 0.0605,-0.0645 0.108363,-0.11767 0.107033,-0.11767 -0.0013,0 -0.04454,0.0286 -0.09507,0.0638 z"
              style="fill: #00ff00; stroke: none"
            />
            <path
              d="m 71.903861,96.179116 c -0.856267,0.59034 -1.722505,1.38013 -2.622649,2.3893 -0.980586,1.10091 -2.011697,2.514944 -2.868629,3.934304 -0.04787,0.0791 -0.07645,0.11966 -0.08177,0.11767 -0.0053,-0.002 -0.475998,-0.31512 -1.047064,-0.69672 -0.571067,-0.38159 -1.042413,-0.69605 -1.047732,-0.69937 -0.0073,-0.004 -0.0066,-0.001 0.0013,0.0106 0.06515,0.0931 2.462433,3.37588 2.465756,3.37654 0.002,0 0.0226,-0.0439 0.04521,-0.0984 0.814385,-1.9399 1.610155,-3.47958 2.551517,-4.937494 0.763195,-1.18135 1.649378,-2.32482 2.59207,-3.34263 0.0605,-0.0645 0.108363,-0.11767 0.107033,-0.11767 -0.0013,0 -0.04454,0.0286 -0.09507,0.0638 z"
              style="fill: #00ff00; stroke: none"
            />
          </g>
        </svg>
      </symbol>

      <symbol id="ion-ios-plus-with-triangle" viewBox="-256 -256 1024 1024">
        <path
          d="M256,48 C141.1 48 48 141.1 48 256 
          s93.1 208 208 208 208-93.1 208-208
          S370.9 48 256 48z
          "
        />
        <!-- m128 217 H264 v119 h-17 V265 H128 v-17 h119 V128 h17 v120 h120 v17z -->
        <path opacity="0.75" d="M511,511 L384,256 L256,384 z"></path>
      </symbol>

      <symbol id="lock-orientation" viewBox="0 0 512 512">
        <!-- https://icon-library.net/icon/rotation-icon-19.html -->
        <path
          d="M320,207.995h-16v-32c0-35.296-28.704-64-64-64s-64,28.704-64,64v32h-16c-8.832,0-16,7.168-16,16v128
			c0,8.832,7.168,16,16,16h160c8.832,0,16-7.168,16-16v-128C336,215.163,328.832,207.995,320,207.995z M272,207.995h-64v-32
			c0-17.632,14.368-32,32-32s32,14.368,32,32V207.995z"
        />
        <path
          d="M501.952,161.147c-8.16-3.264-17.504,0.704-20.768,8.928l-9.568,23.936C444.16,91.643,350.88,15.995,240,15.995
			c-132.352,0-240,107.648-240,240s107.648,240,240,240c64.096,0,124.384-24.96,169.728-70.272c6.24-6.24,6.24-16.384,0-22.624
			s-16.384-6.24-22.624,0c-39.328,39.264-91.552,60.896-147.104,60.896c-114.688,0-208-93.312-208-208s93.312-208,208-208
			c99.872,0,183.488,70.784,203.424,164.8l-32.096-32.128c-6.24-6.24-16.384-6.24-22.624,0s-6.24,16.384,0,22.624l64,64
			c3.008,3.072,7.104,4.704,11.296,4.704c1.056,0,2.144-0.096,3.232-0.32c5.28-1.088,9.632-4.736,11.616-9.728l32-80
			C514.112,173.723,510.144,164.411,501.952,161.147z"
        />
      </symbol>

      <symbol viewBox="0 0 512 512" id="ion-android-cancel">
        <path
          d="M256 48C140.559 48 48 140.559 48 256c0 115.436 92.559 208 208 208 115.435 0 208-92.564 208-208 0-115.441-92.564-208-208-208zm104.002 282.881l-29.12 29.117L256 285.117l-74.881 74.881-29.121-29.117L226.881 256l-74.883-74.881 29.121-29.116L256 226.881l74.881-74.878 29.12 29.116L285.119 256l74.883 74.881z"
        />
      </symbol>

      <symbol id="ios-share" viewBox="0 0 1000 1000">
        <path
          d="M381.9,181l95.8-95.8v525.9c0,13.4,8.9,22.3,22.3,22.3c13.4,0,22.3-8.9,22.3-22.3V85.2l95.8,95.8c4.5,4.5,8.9,6.7,15.6,6.7c6.7,0,11.1-2.2,15.6-6.7c8.9-8.9,8.9-22.3,0-31.2L515.6,16.1c-2.2-2.2-4.5-4.5-6.7-4.5c-4.5-2.2-11.1-2.2-17.8,0c-2.2,2.2-4.5,2.2-6.7,4.5L350.7,149.8c-8.9,8.9-8.9,22.3,0,31.2C359.6,190,373,190,381.9,181z M812,276.9H633.7v44.6H812v624H188v-624h178.3v-44.6H188c-24.5,0-44.6,20.1-44.6,44.6v624c0,24.5,20.1,44.6,44.6,44.6h624c24.5,0,44.6-20.1,44.6-44.6v-624C856.6,296.9,836.5,276.9,812,276.9z"
        />
      </symbol>

      <symbol id="trial" viewBox="0 0 80.2 72.7">
        <path
          d="m 75.2,64 h -3.5 v -9 h 4.1 c 2.4,0 4.4,-2 4.4,-4.4 V 39.4 C 80.2,37 78.2,35 75.8,35 h -4.5 v 0 H 69 v 0 h -4.5 c -2.4,0 -4.4,2 -4.4,4.4 v 3.8 l -7.3,-4.7 v 4.3 H 42.1 41.7 V 20 h 4.1 c 2.4,0 4.4,-2 4.4,-4.4 V 4.4 C 50.2,1.9 48.2,0 45.8,0 H 34.6 c -2.4,0 -4.4,1.9 -4.4,4.4 v 11.2 c 0,2.4 2,4.4 4.4,4.4 h 4 V 42.8 H 38.2 27.5 v -4.3 l -7.4,4.7 V 39.4 C 20.1,37 18.1,35 15.7,35 h -4.5 v 0 H 8.8999996 v 0 H 4.4 C 2,35 0,37 0,39.4 V 50.6 C 0,53 2,55 4.4,55 h 4.1 v 9 H 5.2 l 4.907574,7.39036 c -0.6197224,2.951885 0,0 0,0 L 15.2,64 h -3.5 v -9 h 4.1 c 2.4,0 4.4,-2 4.4,-4.4 v -4.8 l 7.4,4.7 v -4.3 h 10.5 0.1 3.9 0.1 10.6 v 4.3 l 7.4,-4.8 v 4.8 c 0,2.4 2,4.4 4.4,4.4 h 4.1 V 64 h -3.5 l 4.921875,7.078125 z M 34.6,17 c -0.8,0 -1.4,-0.6 -1.4,-1.4 V 4.4 C 33.2,3.6 33.8,3 34.6,3 h 11.2 c 0.8,0 1.4,0.6 1.4,1.4 v 11.2 c 0,0.8 -0.6,1.4 -1.4,1.4 z M 15.8,52 H 4.6 C 3.8,52 3.2,51.4 3.2,50.6 V 39.4 C 3.2,38.6 3.8,38 4.6,38 h 11.2 c 0.8,0 1.4,0.6 1.4,1.4 v 11.2 c 0,0.7 -0.7,1.4 -1.4,1.4 z m 48.8,0 c -0.8,0 -1.4,-0.6 -1.4,-1.4 V 39.4 c 0,-0.8 0.6,-1.4 1.4,-1.4 h 11.2 c 0.8,0 1.4,0.6 1.4,1.4 v 11.2 c 0,0.8 -0.6,1.4 -1.4,1.4 z"
        />
      </symbol>

      <!--
______                    _                       _____            _
| ___ \                  | |                     |_   _|          (_)
| |_/ /__ _ ___  __ _ ___| |__   ___  ___ _ __     | |  ___  _ __  _  ___
|    // _` / __|/ _` / __| '_ \ / _ \/ _ \ '_ \    | | / _ \| '_ \| |/ __|
| |\ \ (_| \__ \ (_| \__ \ | | |  __/  __/ |_) |  _| || (_) | | | | | (__
\_| \_\__,_|___/\__,_|___/_| |_|\___|\___| .__/   \___/\___/|_| |_|_|\___|
                                         | |
      |_|-->

      <!-- From https://github.com/rastasheep/ionicons-sprite/releases  -->
      <symbol viewBox="0 0 512 512" id="ion-ios-home">
        <path
          d="M256 112 L96 240 v208 h112 V320 h96 v128 h112 V240 L256 112z"
        />
        <path
          d="M256 64 l-96 76.8 V96 H96 v96 l-32 25.5 11.51 11.384 L256 84.49 l180.49 144.4 L448 217.5 256 64z"
        />
      </symbol>

      <symbol viewBox="0 0 512 512" id="ion-navicon-round">
        <path
          d="M417.4 224H94.6C77.7 224 64 238.3 64 256s13.7 32 30.6 32h322.8c16.9 0 30.6-14.3 30.6-32s-13.7-32-30.6-32zM417.4 96H94.6C77.7 96 64 110.3 64 128s13.7 32 30.6 32h322.8c16.9 0 30.6-14.3 30.6-32s-13.7-32-30.6-32zM417.4 352H94.6C77.7 352 64 366.3 64 384s13.7 32 30.6 32h322.8c16.9 0 30.6-14.3 30.6-32s-13.7-32-30.6-32z"
        />
      </symbol>

      <symbol id="ion-checkmark" viewBox="0 0 512 512">
        <path
          d="M461.6 109.6l-54.9-43.3c-1.7-1.4-3.8-2.4-6.2-2.4-2.4 0-4.6 1-6.3 2.5L194.5 323s-78.5-75.5-80.7-77.7c-2.2-2.2-5.1-5.9-9.5-5.9s-6.4 3.1-8.7 5.4c-1.7 1.8-29.7 31.2-43.5 45.8-.8.9-1.3 1.4-2 2.1-1.2 1.7-2 3.6-2 5.7 0 2.2.8 4 2 5.7l2.8 2.6s139.3 133.8 141.6 136.1c2.3 2.3 5.1 5.2 9.2 5.2 4 0 7.3-4.3 9.2-6.2l249.1-320c1.2-1.7 2-3.6 2-5.8 0-2.5-1-4.6-2.4-6.4z"
        />
      </symbol>

      <symbol id="ion-ios-trash-outline" viewBox="0 0 512 512">
        <path
          d="M400 113.3h-80v-20c0-16.2-13.1-29.3-29.3-29.3h-69.5C205.1 64 192 77.1 192 93.3v20h-80V128h21.1l23.6 290.7c0 16.2 13.1 29.3 29.3 29.3h141c16.2 0 29.3-13.1 29.3-29.3L379.6 128H400v-14.7zm-193.4-20c0-8.1 6.6-14.7 14.6-14.7h69.5c8.1 0 14.6 6.6 14.6 14.7v20h-98.7v-20zm135 324.6v.8c0 8.1-6.6 14.7-14.6 14.7H186c-8.1 0-14.6-6.6-14.6-14.7v-.8L147.7 128h217.2l-23.3 289.9z"
        />
        <path
          d="M249 160h14v241h-14zm71 0h-14.6l-10.7 241h14.6zm-113.5 0H192l10.7 241h14.6z"
        />
      </symbol>

      <symbol id="ion-ios-plus" viewBox="0 0 512 512">
        <path
          d="M256 48C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48zm128 217H264v119h-17V265H128v-17h119V128h17v120h120v17z"
        />
      </symbol>

      <symbol id="ion-minus" viewBox="0 0 512 512">
        <path d="M64 224h384v64H64z" />
      </symbol>

      <symbol id="ion-eye" viewBox="0 0 512 512">
        <path
          d="M256 128c-81.9 0-145.7 48.8-224 128 67.4 67.7 124 128 224 128 99.9 0 173.4-76.4 224-126.6C428.2 198.6 354.8 128 256 128zm0 219.3c-49.4 0-89.6-41-89.6-91.3 0-50.4 40.2-91.3 89.6-91.3s89.6 41 89.6 91.3c0 50.4-40.2 91.3-89.6 91.3z"
        />
        <path
          d="M256 224c0-7.9 2.9-15.1 7.6-20.7-2.5-.4-5-.6-7.6-.6-28.8 0-52.3 23.9-52.3 53.3s23.5 53.3 52.3 53.3 52.3-23.9 52.3-53.3c0-2.3-.2-4.6-.4-6.9-5.5 4.3-12.3 6.9-19.8 6.9-17.8 0-32.1-14.3-32.1-32z"
        />
      </symbol>

      <symbol id="ion-ios-gear" viewBox="0 0 512 512">
        <path
          d="M416.349 256.046c-.001-21.013 13.143-38.948 31.651-46.062a196.302 196.302 0 0 0-23.664-57.139 49.423 49.423 0 0 1-20.082 4.254c-12.621 0-25.238-4.811-34.871-14.442-14.863-14.863-18.248-36.846-10.18-54.97A196.274 196.274 0 0 0 302.074 64C294.971 82.529 277.027 95.69 256 95.69c-21.025 0-38.969-13.161-46.073-31.69a196.243 196.243 0 0 0-57.128 23.688c8.068 18.122 4.683 40.104-10.181 54.97-9.631 9.631-22.25 14.443-34.871 14.443a49.429 49.429 0 0 1-20.083-4.255A196.273 196.273 0 0 0 64 209.984c18.509 7.112 31.652 25.049 31.652 46.062 0 21.008-13.132 38.936-31.63 46.054a196.318 196.318 0 0 0 23.692 57.128 49.428 49.428 0 0 1 20.032-4.232c12.622 0 25.239 4.812 34.871 14.443 14.841 14.841 18.239 36.781 10.215 54.889a196.257 196.257 0 0 0 57.13 23.673c7.128-18.479 25.046-31.596 46.038-31.596 20.992 0 38.91 13.115 46.037 31.596a196.234 196.234 0 0 0 57.132-23.675c-8.023-18.106-4.626-40.046 10.216-54.887 9.629-9.632 22.248-14.444 34.868-14.444 6.836 0 13.67 1.411 20.033 4.233a196.318 196.318 0 0 0 23.692-57.128c-18.498-7.119-31.629-25.048-31.629-46.054zM256.9 335.9c-44.3 0-80-35.9-80-80 0-44.101 35.7-80 80-80 44.299 0 80 35.899 80 80 0 44.1-35.701 80-80 80z"
        />
      </symbol>

      <symbol id="ion-levels" viewBox="0 0 512 512">
        <path
          d="M127 99.2V80c0-8.8-7.2-16-16-16s-16 7.2-16 16v21.5c-8.5 5.8-14 15.5-14 26.5s5.5 20.7 14 26.5V432c0 8.8 7.2 16 16 16s16-7.2 16-16V156.8c10.7-5.2 18-16.1 18-28.8s-7.3-23.6-18-28.8zM223 292.9V80c0-8.8-7.2-16-16-16s-16 7.2-16 16v211.7c-10.1 5.4-17 16-17 28.3s6.9 22.9 17 28.3V432c0 8.8 7.2 16 16 16s16-7.2 16-16v-84.9c9-5.7 15-15.7 15-27.1s-6-21.4-15-27.1zM319 163.3V80c0-8.8-7.2-16-16-16s-16 7.2-16 16v83.3c-9.6 5.5-16 15.9-16 27.7s6.4 22.2 16 27.7V432c0 8.8 7.2 16 16 16s16-7.2 16-16V218.7c9.6-5.5 16-15.9 16-27.7s-6.4-22.2-16-27.7zM431 383c0-11.8-6.4-22.2-16-27.7V80c0-8.8-7.2-16-16-16s-16 7.2-16 16v275.3c-9.6 5.5-16 15.9-16 27.7s6.4 22.2 16 27.7V432c0 8.8 7.2 16 16 16s16-7.2 16-16v-21.3c9.6-5.5 16-15.9 16-27.7z"
        />
      </symbol>

      <symbol id="ion-ios-paper-outline" viewBox="0 0 512 512">
        <path
          d="M112 64v336h16V80h304v337.143c0 8.205-6.652 14.857-14.857 14.857H94.857C86.652 432 80 425.348 80 417.143V128h16v-16H64v305.143C64 434.157 77.843 448 94.857 448h322.285C434.157 448 448 434.157 448 417.143V64H112z"
        />
        <path
          d="M160 112h128v16H160zM160 192h240v16H160zM160 272h192v16H160zM160 352h240v16H160z"
        />
      </symbol>

      <symbol id="ion-grid" viewBox="0 0 512 512">
        <path
          d="M160 153.3c0 3.7-3 6.7-6.7 6.7h-50.5c-3.7 0-6.7-3-6.7-6.7v-50.5c0-3.7 3-6.7 6.7-6.7h50.5c3.7 0 6.7 3 6.7 6.7v50.5zM288 153.3c0 3.7-3 6.7-6.7 6.7h-50.5c-3.7 0-6.7-3-6.7-6.7v-50.5c0-3.7 3-6.7 6.7-6.7h50.5c3.7 0 6.7 3 6.7 6.7v50.5zM416 153.3c0 3.7-3 6.7-6.7 6.7h-50.5c-3.7 0-6.7-3-6.7-6.7v-50.5c0-3.7 3-6.7 6.7-6.7h50.5c3.7 0 6.7 3 6.7 6.7v50.5z"
        />
        <g>
          <path
            d="M160 281.3c0 3.7-3 6.7-6.7 6.7h-50.5c-3.7 0-6.7-3-6.7-6.7v-50.5c0-3.7 3-6.7 6.7-6.7h50.5c3.7 0 6.7 3 6.7 6.7v50.5zM288 281.3c0 3.7-3 6.7-6.7 6.7h-50.5c-3.7 0-6.7-3-6.7-6.7v-50.5c0-3.7 3-6.7 6.7-6.7h50.5c3.7 0 6.7 3 6.7 6.7v50.5zM416 281.3c0 3.7-3 6.7-6.7 6.7h-50.5c-3.7 0-6.7-3-6.7-6.7v-50.5c0-3.7 3-6.7 6.7-6.7h50.5c3.7 0 6.7 3 6.7 6.7v50.5z"
          />
        </g>
        <g>
          <path
            d="M160 409.3c0 3.7-3 6.7-6.7 6.7h-50.5c-3.7 0-6.7-3-6.7-6.7v-50.5c0-3.7 3-6.7 6.7-6.7h50.5c3.7 0 6.7 3 6.7 6.7v50.5zM288 409.3c0 3.7-3 6.7-6.7 6.7h-50.5c-3.7 0-6.7-3-6.7-6.7v-50.5c0-3.7 3-6.7 6.7-6.7h50.5c3.7 0 6.7 3 6.7 6.7v50.5zM416 409.3c0 3.7-3 6.7-6.7 6.7h-50.5c-3.7 0-6.7-3-6.7-6.7v-50.5c0-3.7 3-6.7 6.7-6.7h50.5c3.7 0 6.7 3 6.7 6.7v50.5z"
          />
        </g>
      </symbol>

      <symbol id="ion-edit" viewBox="0 0 512 512">
        <path
          d="M163 439.573l-90.569-90.569L322.78 98.656l90.57 90.569zM471.723 88.393l-48.115-48.114c-11.723-11.724-31.558-10.896-44.304 1.85l-45.202 45.203 90.569 90.568 45.202-45.202c12.743-12.746 13.572-32.582 1.85-44.305zM64.021 363.252L32 480l116.737-32.021z"
        />
      </symbol>

      <symbol id="ion-android-options" viewBox="0 0 512 512">
        <path
          d="M32 384h272v32H32zM400 384h80v32h-80zM384 447.5c0 17.949-14.327 32.5-32 32.5-17.673 0-32-14.551-32-32.5v-95c0-17.949 14.327-32.5 32-32.5 17.673 0 32 14.551 32 32.5v95z"
        />
        <g>
          <path
            d="M32 240h80v32H32zM208 240h272v32H208zM192 303.5c0 17.949-14.327 32.5-32 32.5-17.673 0-32-14.551-32-32.5v-95c0-17.949 14.327-32.5 32-32.5 17.673 0 32 14.551 32 32.5v95z"
          />
        </g>
        <g>
          <path
            d="M32 96h272v32H32zM400 96h80v32h-80zM384 159.5c0 17.949-14.327 32.5-32 32.5-17.673 0-32-14.551-32-32.5v-95c0-17.949 14.327-32.5 32-32.5 17.673 0 32 14.551 32 32.5v95z"
          />
        </g>
      </symbol>

      <symbol id="ion-network" viewBox="0 0 512 512">
        <path
          d="M448 96c0-35.29-28.71-64-64-64s-64 28.71-64 64c0 23.637 12.89 44.307 32 55.391v52.832l-96 48-96-48v-52.832c19.11-11.084 32-31.754 32-55.391 0-35.29-28.71-64-64-64S64 60.71 64 96c0 23.637 12.89 44.307 32 55.391v92.387l128 64v52.832c-19.11 11.084-32 31.754-32 55.391 0 35.29 28.71 64 64 64s64-28.71 64-64c0-23.637-12.89-44.307-32-55.391v-52.832l128-64v-92.387c19.11-11.084 32-31.754 32-55.391zM128 64c17.673 0 32 14.327 32 32s-14.327 32-32 32-32-14.327-32-32 14.327-32 32-32zm128 384c-17.673 0-32-14.327-32-32s14.327-32 32-32 32 14.327 32 32-14.327 32-32 32zm128-320c-17.673 0-32-14.327-32-32s14.327-32 32-32 32 14.327 32 32-14.327 32-32 32z"
        />
      </symbol>

      <symbol id="ion-android-people" viewBox="0 0 512 512">
        <path
          d="M337.454 232c33.599 0 61.092-27.002 61.092-60 0-32.997-27.493-60-61.092-60s-61.09 27.003-61.09 60c0 32.998 27.491 60 61.09 60zm-162.908 0c33.599 0 61.09-27.002 61.09-60 0-32.997-27.491-60-61.09-60s-61.092 27.003-61.092 60c0 32.998 27.493 60 61.092 60zm0 44C126.688 276 32 298.998 32 346v54h288v-54c0-47.002-97.599-70-145.454-70zm162.908 11.003c-6.105 0-10.325 0-17.454.997 23.426 17.002 32 28 32 58v54h128v-54c0-47.002-94.688-58.997-142.546-58.997z"
        />
      </symbol>

      <symbol id="ion-android-locate" viewBox="0 0 512 512">
        <path
          d="M256 176c-44.004 0-80.001 36-80.001 80 0 44.004 35.997 80 80.001 80 44.005 0 79.999-35.996 79.999-80 0-44-35.994-80-79.999-80zm190.938 58.667c-9.605-88.531-81.074-160-169.605-169.599V32h-42.666v33.067c-88.531 9.599-160 81.068-169.604 169.599H32v42.667h33.062c9.604 88.531 81.072 160 169.604 169.604V480h42.666v-33.062c88.531-9.604 160-81.073 169.605-169.604H480v-42.667h-33.062zM256 405.333c-82.137 0-149.334-67.198-149.334-149.333 0-82.136 67.197-149.333 149.334-149.333 82.135 0 149.332 67.198 149.332 149.333S338.135 405.333 256 405.333z"
        />
      </symbol>
      <symbol id="ion-folder" viewBox="0 0 512 512">
        <path
          d="M430.1 192H81.9c-17.7 0-18.6 9.2-17.6 20.5l13 183c.9 11.2 3.5 20.5 21.1 20.5h316.2c18 0 20.1-9.2 21.1-20.5l12.1-185.3c.9-11.2 0-18.2-17.7-18.2zM426.2 143.3c-.5-12.4-4.5-15.3-15.1-15.3H267.9c-21.8 0-24.4.3-40.9-17.4-13.7-14.8-8.3-14.6-36.6-14.6h-75.3c-17.4 0-23.6-1.5-25.2 16.6-1.5 16.7-5 57.2-5.5 63.4h343.4l-1.6-32.7z"
        />
      </symbol>
      <symbol id="ion-ios-plus-outline" viewBox="0 0 512 512">
        <path
          d="M256 48C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48zm0 398.7c-105.1 0-190.7-85.5-190.7-190.7S150.9 65.3 256 65.3 446.7 150.9 446.7 256 361.1 446.7 256 446.7z"
        />
        <path
          d="M264.1 128h-16.8v119.9H128v16.8h119.3V384h16.8V264.7H384v-16.8H264.1z"
        />
      </symbol>
      <symbol id="ion-ios-location" viewBox="0 0 512 512">
        <path
          d="M256 32c-79.529 0-144 64.471-144 144 0 112 144 304 144 304s144-192 144-304c0-79.529-64.471-144-144-144zm0 190.9c-25.9 0-46.9-21-46.9-46.9s21-46.9 46.9-46.9 46.9 21 46.9 46.9-21 46.9-46.9 46.9z"
        />
      </symbol>
      <symbol id="ion-chevron-left" viewBox="0 0 512 512">
        <path
          d="M213.7 256L380.9 81.9c4.2-4.3 4.1-11.4-.2-15.8l-29.9-30.6c-4.3-4.4-11.3-4.5-15.5-.2L131.1 247.9c-2.2 2.2-3.2 5.2-3 8.1-.1 3 .9 5.9 3 8.1l204.2 212.7c4.2 4.3 11.2 4.2 15.5-.2l29.9-30.6c4.3-4.4 4.4-11.5.2-15.8L213.7 256z"
        />
      </symbol>
      <symbol id="ion-chevron-right" viewBox="0 0 512 512">
        <path
          d="M298.3 256L131.1 81.9c-4.2-4.3-4.1-11.4.2-15.8l29.9-30.6c4.3-4.4 11.3-4.5 15.5-.2L380.9 248c2.2 2.2 3.2 5.2 3 8.1.1 3-.9 5.9-3 8.1L176.7 476.8c-4.2 4.3-11.2 4.2-15.5-.2L131.3 446c-4.3-4.4-4.4-11.5-.2-15.8L298.3 256z"
        />
      </symbol>
      <symbol id="ion-chevron-down" viewBox="0 0 512 512">
        <path
          d="M256 298.3l174.2-167.2c4.3-4.2 11.4-4.1 15.8.2l30.6 29.9c4.4 4.3 4.5 11.3.2 15.5L264.1 380.9c-2.2 2.2-5.2 3.2-8.1 3-3 .1-5.9-.9-8.1-3L35.2 176.7c-4.3-4.2-4.2-11.2.2-15.5L66 131.3c4.4-4.3 11.5-4.4 15.8-.2L256 298.3z"
        />
      </symbol>
      <symbol id="ion-chevron-up" viewBox="0 0 512 512">
        <path
          d="M256 213.7l174.2 167.2c4.3 4.2 11.4 4.1 15.8-.2l30.6-29.9c4.4-4.3 4.5-11.3.2-15.5L264.1 131.1c-2.2-2.2-5.2-3.2-8.1-3-3-.1-5.9.9-8.1 3L35.2 335.3c-4.3 4.2-4.2 11.2.2 15.5L66 380.7c4.4 4.3 11.5 4.4 15.8.2L256 213.7z"
        />
      </symbol>
      <symbol id="ion-android-person" viewBox="0 0 512 512">
        <path
          d="M256 256c52.805 0 96-43.201 96-96s-43.195-96-96-96-96 43.201-96 96 43.195 96 96 96zm0 48c-63.598 0-192 32.402-192 96v48h384v-48c0-63.598-128.402-96-192-96z"
        />
      </symbol>

      <symbol id="ion-ios-list-outline" viewBox="0 0 512 512">
        <path d="M432 80v352H80V80h352m16-16H64v384h384V64z" />
        <path
          d="M192 152h192v16H192zM192 248h192v16H192zM192 344h192v16H192z"
        />
        <circle cx="144" cy="160" r="16" />
        <circle cx="144" cy="256" r="16" />
        <circle cx="144" cy="352" r="16" />
      </symbol>

      <symbol id="ion-ios-minus" viewBox="0 0 512 512">
        <path
          d="M256 48C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48zm128 217H128v-17h256v17z"
        />
      </symbol>

      <symbol id="cloud-padlock" viewBox="0 0 100 100">
        <!--  https://www.uscloud.com/wp-content/uploads/2017/04/cloud-locked-blue.svg  -->
        <path
          d="M69.53,100H30.47A1.5,1.5,0,0,1,29,98.5V66.38a1.5,1.5,0,0,1,1.5-1.5H69.53a1.5,1.5,0,0,1,1.5,1.5V98.5A1.5,1.5,0,0,1,69.53,100ZM32,97H68V67.88H32Z"
        />
        <path
          d="M50,88.69a7.58,7.58,0,1,1,7.58-7.58A7.59,7.59,0,0,1,50,88.69Zm0-12.16a4.58,4.58,0,1,0,4.58,4.58A4.59,4.59,0,0,0,50,76.53Z"
        />
        <path
          d="M50,92.43a1.5,1.5,0,0,1-1.5-1.5V87.19a1.5,1.5,0,0,1,3,0v3.74A1.5,1.5,0,0,1,50,92.43Z"
        />
        <path
          d="M61.71,67.88a1.5,1.5,0,0,1-1.5-1.5V62.8a10.21,10.21,0,0,0-20.42,0v3.59a1.5,1.5,0,0,1-3,0V62.8a13.21,13.21,0,0,1,26.42,0v3.59A1.5,1.5,0,0,1,61.71,67.88Z"
        />
        <path
          d="M15.3,30.92a1.5,1.5,0,0,1-1.48-1.29,17.33,17.33,0,0,1-.18-2.46,17.07,17.07,0,0,1,33.49-4.65,1.5,1.5,0,0,1-2.89.82,14.07,14.07,0,0,0-27.61,3.83,14.28,14.28,0,0,0,.15,2,1.5,1.5,0,0,1-1.27,1.7Z"
        />
        <path
          d="M76.91,75a1.5,1.5,0,0,1-.08-3,21.31,21.31,0,0,0-1.15-42.6,1.5,1.5,0,0,1,0-3A24.31,24.31,0,0,1,77,75Z"
        />
        <path
          d="M22.86,74.93h-.11a24.49,24.49,0,0,1-8-46.88,1.5,1.5,0,1,1,1.2,2.75A21.49,21.49,0,0,0,23,71.93a1.5,1.5,0,0,1-.1,3Z"
        />
        <path
          d="M83.31,30.66h-.06a1.5,1.5,0,0,1-1.44-1.56q0-.52,0-1A25.08,25.08,0,0,0,36.54,13.2a1.5,1.5,0,0,1-2.42-1.78,28.08,28.08,0,0,1,50.7,16.64q0,.58,0,1.16A1.5,1.5,0,0,1,83.31,30.66Z"
        />
      </symbol>

      <symbol id="key" viewBox="0 0 319.949 319.949">
        <!-- https://www.svgrepo.com/svg/34735/key -->
        <g>
          <path
            d="M261.891,319.945c-2.599,0-5.101-1.012-6.97-2.828l-2.515-2.444l-22.722,0.657c-8.579,0.197-15.594-6.484-15.832-15.109
		l-0.34-12.18l-11.554,0.334c-0.145,0.004-0.295,0.006-0.446,0.006c-8.395,0-15.153-6.641-15.385-15.119l-0.337-12.174
		l-11.555,0.334c-0.154,0.004-0.295,0.006-0.437,0.006c-8.4,0-15.162-6.642-15.395-15.121l-0.677-24.32
		c-2.093-2.127-5.762-5.599-9.706-9.23c-3.898,2.241-8.346,3.461-12.896,3.461c-6.772,0-13.196-2.62-18.086-7.377
		c-0.2-0.194-0.392-0.397-0.575-0.607c-0.483-0.557-1.007-1.175-1.538-1.828c-3.937,0.455-7.903,0.685-11.859,0.685
		c-27.553,0-53.453-10.775-72.931-30.343c-40.182-40.362-40.182-106.036,0-146.399C49.615,10.78,75.517,0.004,103.071,0.004
		s53.455,10.776,72.934,30.344c23.722,23.828,34.284,57.301,28.743,90.367c2.623,4.08,4.098,8.83,4.234,13.808
		c0.117,4.269-0.747,8.412-2.491,12.158l109.77,110.439c1.794,1.806,2.832,4.227,2.903,6.771l0.774,27.738
		c0.401,14.682-10.999,26.963-25.415,27.383l-32.346,0.93C262.082,319.944,261.986,319.945,261.891,319.945z M256.344,294.554
		c2.599,0,5.101,1.013,6.97,2.829l2.515,2.444l28.117-0.809c3.407-0.099,6.102-3.167,6.001-6.838l-0.664-23.772L187.876,156.323
		c-1.504-1.514-2.484-3.469-2.799-5.579l-0.383-2.574c-0.443-2.987,0.485-6.015,2.529-8.238c1.189-1.294,1.817-3.021,1.767-4.86
		c-0.049-1.788-0.752-3.446-1.978-4.669c-2.407-2.398-3.431-5.855-2.719-9.178c5.987-27.927-2.41-56.624-22.463-76.768
		c-15.697-15.769-36.565-24.453-58.76-24.453S60.009,28.688,44.31,44.457c-32.437,32.582-32.437,85.598,0,118.179
		c15.697,15.77,36.563,24.453,58.757,24.453c5.311,0,10.642-0.513,15.848-1.522c4.327-0.844,8.692,1.251,10.749,5.148
		c0.576,1.092,0.369,4.093,5.202,4.926c1.617,0.279,3.435-0.101,4.591-1.331l1.295-1.469c1.764-2,4.251-3.213,6.912-3.369
		c2.676-0.154,5.275,0.755,7.261,2.533c0.2,0.18,4.968,4.451,9.827,8.99c10.646,9.945,12.744,12.306,12.874,17.028l0.647,23.272
		l16.947-0.49c2.676-0.084,5.229,0.906,7.157,2.728c1.93,1.823,3.055,4.339,3.128,6.992l0.491,17.724l16.943-0.49
		c2.677-0.086,5.229,0.905,7.156,2.728c1.93,1.822,3.055,4.337,3.129,6.989l0.495,17.729l22.334-0.646
		C256.151,294.555,256.248,294.554,256.344,294.554z"
          />
          <path
            d="M84.895,120.428c-9.363,0-18.163-3.659-24.779-10.305c-13.631-13.695-13.63-35.974,0-49.664
		c6.616-6.646,15.418-10.308,24.782-10.308s18.166,3.661,24.782,10.309c13.629,13.69,13.627,35.968-0.003,49.659
		C103.062,116.766,94.261,120.428,84.895,120.428z M84.898,70.152c-4.005,0-7.772,1.569-10.608,4.418
		c-5.885,5.911-5.885,15.53,0.001,21.443c2.834,2.847,6.601,4.415,10.604,4.415c4.005,0,7.771-1.569,10.607-4.418
		c5.886-5.913,5.887-15.53,0.004-21.44C92.671,71.721,88.903,70.152,84.898,70.152z"
          />
          <path
            d="M271.669,282.908c-2.566,0-5.133-0.981-7.087-2.945l-88.698-89.1c-3.896-3.914-3.882-10.245,0.032-14.142
		c3.912-3.895,10.245-3.884,14.142,0.032l88.698,89.1c3.896,3.914,3.882,10.245-0.032,14.142
		C276.773,281.937,274.221,282.908,271.669,282.908z"
          />
        </g>
      </symbol>

      <symbol id="robot" viewBox="0 0 511.999 511.999">
        <!-- https://www.svgrepo.com/svg/72257/robot -->
        <path
          d="M424.464,226.038h-31.289c-4.637,0-8.394,3.758-8.394,8.394v17.485H370.51v-17.485c0-4.636-3.757-8.394-8.394-8.394
			h-34.743v-17.351h34.743c4.637,0,8.394-3.758,8.394-8.394V66.121c0-20.285-16.502-36.787-36.787-36.787h-69.329V8.394
			c0-4.636-3.757-8.394-8.394-8.394c-4.637,0-8.394,3.758-8.394,8.394v20.939h-69.33c-20.285,0-36.787,16.503-36.787,36.787v134.173
			c0,4.636,3.757,8.394,8.394,8.394h34.744v17.351h-34.744c-4.637,0-8.394,3.758-8.394,8.394v17.485h-14.271v-17.485
			c0-4.636-3.757-8.394-8.394-8.394h-31.29c-4.637,0-8.394,3.758-8.394,8.394v169.096c0,4.636,3.757,8.394,8.394,8.394h31.289
			c4.637,0,8.394-3.758,8.394-8.394V303.215h14.271v68.203c0,4.636,3.757,8.394,8.394,8.394h14.9v75.713h-10.586
			c-4.637,0-8.394,3.758-8.394,8.394v39.686c0,4.636,3.757,8.394,8.394,8.394h70.744c4.637,0,8.394-3.758,8.394-8.394v-39.686
			c0-4.636-3.757-8.394-8.394-8.394h-10.585v-75.713h83.289v75.713h-10.586c-4.637,0-8.394,3.758-8.394,8.394v39.686
			c0,4.636,3.757,8.394,8.394,8.394h70.744c4.637,0,8.394-3.758,8.394-8.394v-39.686c0-4.636-3.757-8.394-8.394-8.394h-10.585
			v-75.713h14.9c4.637,0,8.394-3.758,8.394-8.394v-68.203h14.271v100.312c0,4.636,3.757,8.394,8.394,8.394h31.289
			c4.637,0,8.394-3.758,8.394-8.394V234.431C432.857,229.796,429.1,226.038,424.464,226.038z M110.43,395.134H95.929V242.826h14.501
			V395.134z M141.489,286.428h-14.271v-17.723h14.271V286.428z M158.276,191.9V66.121c0-11.028,8.972-20,20-20h155.447
			c11.028,0,20,8.972,20,20V191.9H158.276z M310.586,208.686v17.351H201.414v-17.351H310.586z M216.547,472.314v22.898h-53.957
			v-22.898H216.547z M181.571,455.526v-75.713h15.998v75.713H181.571z M349.409,472.314v22.898h-53.957v-22.898H349.409z
      M314.433,455.526v-75.713h15.998v75.713h0H314.433z M353.723,363.024H158.276V242.826h195.446V363.024z M384.781,286.428H370.51
			v-17.723h14.271V286.428z M416.07,395.134h-14.501V242.826h14.501V395.134z"
        />
        <path
          d="M204.236,84.744c-20.803,0-37.727,16.924-37.727,37.726c0,20.802,16.924,37.726,37.727,37.726
			c20.802,0.001,37.726-16.923,37.726-37.726C241.963,101.668,225.039,84.744,204.236,84.744z M204.236,143.409
			c-11.545,0-20.94-9.392-20.94-20.939c0-11.545,9.394-20.939,20.94-20.939c11.545,0,20.939,9.393,20.939,20.939
			C225.175,134.016,215.782,143.409,204.236,143.409z"
        />
        <path
          d="M307.765,84.744c-20.803,0-37.727,16.924-37.727,37.726c0,20.802,16.924,37.727,37.727,37.727
			c20.802,0,37.726-16.924,37.726-37.726S328.567,84.744,307.765,84.744z M307.765,143.409c-11.545,0-20.94-9.393-20.94-20.939
			c0-11.545,9.393-20.939,20.94-20.939c11.545,0,20.939,9.393,20.939,20.939C328.703,134.016,319.31,143.409,307.765,143.409z"
        />
        <path
          d="M256.001,270.9c-30.317,0-54.982,24.664-54.982,54.982c0,4.636,3.757,8.394,8.394,8.394h93.176
			c4.637,0,8.394-3.758,8.394-8.394C310.982,295.565,286.318,270.9,256.001,270.9z M218.735,317.488
			c3.835-17.036,19.089-29.8,37.265-29.8c18.177,0,33.43,12.764,37.265,29.8H218.735z"
        />
        <circle cx="326.744" cy="267.215" r="8.394" />
        <circle cx="185.257" cy="267.215" r="8.394" />
      </symbol>

      <symbol id="eyes" viewBox="-1 -1 98.273903 62.182804">
        <g transform="translate(-26.275916,-75.041808)">
          <ellipse
            style="fill: #000000; stroke: none; stroke-width: 2.19181"
            id="path2376"
            cx="54.900955"
            cy="119.86971"
            rx="9.9104872"
            ry="13.213983"
          />
          <ellipse
            style="fill: #000000; stroke: none; stroke-width: 2.19181"
            cx="103.50953"
            cy="119.86971"
            rx="9.9104872"
            ry="13.213983"
          />
          <ellipse
            style="fill: #ffffff; stroke: none; stroke-width: 0.571668"
            id="path2376-4-3"
            cx="45.45673"
            cy="154.1487"
            rx="2.3596396"
            ry="3.7754233"
            transform="rotate(-26.665927)"
          />
          <ellipse
            style="fill: #ffffff; stroke: none; stroke-width: 0.571668"
            id="path2376-4-3-8"
            cx="2.1600578"
            cy="131.70082"
            rx="2.3596396"
            ry="3.7754233"
            transform="rotate(-26.665927)"
          />
          <ellipse
            style="fill: none; stroke-width: 5"
            cx="50.237816"
            cy="105.23994"
            rx="21.865995"
            ry="29.102224"
          />
          <ellipse
            style="fill: none; stroke-width: 5"
            cx="96.587921"
            cy="106.02648"
            rx="21.865995"
            ry="29.102224"
          />
        </g>
      </symbol>

      <symbol id="female-manager" viewBox="0 0 474 544">
        <g transform="translate(-113.276,-8.487)">
          <path
            d="m 532.17,388.86 -22.535,-11.746 -112.2,174.4 h 109.92 v -63.52 h 0.71453 l 16.25447,63.52 h 53.922 c 4.75,0 8.4844,-3.8164 8.4844,-8.4844 v -62.871 c 0,-38.098 -20.871,-73.059 -54.559,-91.301 z"
          />
          <path
            d="m 190.94,377.92 -21.324,11.363 c -34.703,17.816 -56.34,53.113 -56.34,92.145 v 61.602 c 0,4.668 3.8164,8.4844 8.4844,8.4844 h 54.016 l 17.30314,-63.52 h -0.33414 v 63.52 h 109.88 z"
          />
          <path
            d="m 468.96,245.13 h 0.0859 c 2.8867,-7.4648 5.2617,-15.188 7.2109,-22.824 9.332,-2.7148 12.64629,-15.27903 17.99029,-28.43103 11.711,-29.02 3.83115,-38.70059 -12.03595,-35.22359 -0.0859,-0.84766 -3.57904,-57.16938 -4.08684,-60.30938 0,-49.551 -40.305,-89.855 -89.938,-89.855 h -76.363 c -49.551,0 -89.938,40.305 -89.855,89.262 -0.16797,1.6953 -1.32632,36.05169 -2.51382,58.19569 -30.0003,1.16196 -18.60794,45.49088 6.03993,62.68795 9.25,37.078 28.03999,74.69036 56.20799,96.24036 v 14.68 l -85.20808,44.5817 108.4406,178.43398 88.55495,1.04867 L 501.83613,373.4647 418.3864,329.559 v -14.762 c 22.23,-17.055 39.711,-41.746 50.57,-69.66 z m -44.461,106.74 -74.496,170.04 -74.414,-169.87 15.273,-8.0625 c 4.4141,-0.33984 7.8047,-4.0742 7.8047,-8.4844 l 0.004,-9.6719 c 15.441,8.2305 32.668,12.98 51.332,12.98 18.668,0 35.891,-4.8359 51.418,-13.066 v 9.7578 c 0,4.4141 3.4805,8.0625 7.8047,8.4844 z M 247.169,239.62 c 0,-0.84766 -4.4961,-10.012 -8.0625,-26.898 -0.93359,-4.4141 -5.1758,-7.2969 -9.6719,-6.6172 -2.0391,0.33984 -9.1641,-4.4961 -13.66,-15.527 -5.0078,-12.219 -2.375,-20.109 -1.2734,-20.617 2.9688,-0.76172 10.945,11.965 18.414,2.5469 11.879,7.4648 35.43549,0.25255 42.73249,-3.05645 -11.35254,-38.5198 -27.63099,-58.79855 16.57651,-75.93555 -26.34109,90.15758 90.64286,56.48206 130.85288,70.15923 -0.33984,4.8359 17.63762,21.22387 22.64152,21.22387 6.5352,0 8.1445,-5.9375 19.602,-17.734 v 0.42578 c 0.33984,7.4648 9.1641,10.945 14.426,5.8555 3.4805,-3.3945 5.6836,-3.5625 5.7695,-3.4805 1.1875,0.42578 3.7344,8.5703 -1.1875,20.617 -4.4961,11.031 -11.539,16.035 -13.66,15.527 -11.199,-1.6953 -8.4844,9.9258 -17.48,32.922 -42.938,110.47 -163.25,109.96 -206.02,0.58984 z"
          />
          <path
            d="m 262.18,320.73 -77.297,41.238 -23.25,-3.9023 c -3.8164,-0.67969 -6.7891,-3.9023 -7.043,-7.8047 -0.25391,-3.9023 2.207,-7.5508 6.0234,-8.6562 31.141,-9.5859 67.30379,-45.5061 78.67079,-83.6041 10.102,19.43 26.22803,36.57 41.24803,50.145 z"
          />
          <path
            d="m 545.41,350.25 c -0.25391,3.9023 -3.1406,7.1289 -7.043,7.8047 l -21.637,3.6484 -91.93244,-53.6501 c 15.188,-13.66 24.49764,-28.53165 34.51364,-47.96465 11.453,38.098 49.1068,72.00275 80.1618,81.42075 3.7305,1.1914 6.1914,4.7539 5.9375,8.7422 z"
          />
          <ellipse
            style="fill: #000000"
            cx="381.73688"
            cy="198.29723"
            rx="12.321936"
            ry="11.535429"
          />
          <ellipse
            style="fill: #000000"
            cx="324.58408"
            cy="198.29723"
            rx="12.321936"
            ry="11.535429"
          />
          <path
            style="stroke: #000000; stroke-width: 6"
            d="m 316.17479,268.54326 c 21.92273,13.8341 34.36535,21.79919 51.54803,12.57646 17.18269,-9.22275 17.18269,-9.22275 17.18269,-9.22275"
          />
          <path
            style="stroke: #000000; stroke-width: 2"
            d="m 342.16532,248.35466 c 5.35641,5.5837 8.39653,8.79856 12.59479,5.07609 4.19827,-3.72247 4.19827,-3.72247 4.19827,-3.72247"
          />
        </g>
      </symbol>

      <!--
 _____      _  __       _
/  ___|    | |/ _|     | |
\ `--.  ___| | |_    __| |_ __ __ ___      ___ __
 `--. \/ _ \ |  _|  / _` | '__/ _` \ \ /\ / / '_ \
/\__/ /  __/ | |   | (_| | | | (_| |\ V  V /| | | |
\____/ \___|_|_|    \__,_|_|  \__,_| \_/\_/ |_| |_|
      -->
      <symbol id="referred-in" viewBox="0 0 90 68">
        <!-- raw is referred-in.svg, simplified using https://jakearchibald.github.io/svgomg/ and then
      manually tweaked
        -->
        <defs>
          <marker orient="auto" id="a" overflow="visible">
            <path d="M4 0L-2 4v-8z" fill="white" />
          </marker>
        </defs>
        <g transform="translate(11.44 -83.48)">
          <ellipse
            cx="59.42"
            cy="109.79"
            rx="7.25"
            ry="7.25"
            fill="none"
            stroke="white"
            stroke-width="3.7"
          />
          <path
            d="M46.68 131.67a12.5 12.5 0 0 1 12.46-12.5 12.5 12.5 0 0 1 12.54 12.43l-12.5.08z"
          />
        </g>
        <g transform="translate(-1.02 -51.81) scale(.52727)">
          <ellipse
            ry="7.25"
            rx="7.25"
            cy="109.79"
            cx="59.42"
            fill="none"
            stroke="white"
            stroke-width="3.7"
          />
          <path
            d="M46.68 131.67a12.5 12.5 0 0 1 12.46-12.5 12.5 12.5 0 0 1 12.54 12.43l-12.5.08z"
          />
        </g>
        <g transform="translate(-1.02 -27.3) scale(.52727)">
          <ellipse
            cx="59.42"
            cy="109.79"
            rx="7.25"
            ry="7.25"
            fill="none"
            stroke="white"
            stroke-width="3.7"
          />
          <path
            d="M46.68 131.67a12.5 12.5 0 0 1 12.46-12.5 12.5 12.5 0 0 1 12.54 12.43l-12.5.08z"
          />
        </g>
        <g transform="translate(-1.02 -5.24) scale(.52727)">
          <ellipse
            ry="7.25"
            rx="7.25"
            cy="109.79"
            cx="59.42"
            fill="none"
            stroke="white"
            stroke-width="3.7"
          />
          <path
            d="M46.68 131.67a12.5 12.5 0 0 1 12.46-12.5 12.5 12.5 0 0 1 12.54 12.43l-12.5.08z"
          />
        </g>
        <g fill="none" stroke="white" stroke-width="1">
          <path d="M.41.28C6.16 8.65 12.85 12 24.06 13.55" />
          <path
            d="M69.45 113.03l12.92-1.94"
            marker-end="url(#a)"
            transform="matrix(1 0 0 -1 -32.26 128.44)"
          />
        </g>
        <g fill="none" stroke="white" stroke-width="1">
          <path d="M.41 67.38c5.75-8.36 12.44-11.72 23.65-13.26" />
          <path
            d="M69.45 113.03l12.92-1.94"
            marker-end="url(#a)"
            transform="translate(-32.26 -60.78)"
          />
        </g>
        <g fill="none" stroke="white" stroke-width="1">
          <path d="M.41 35.28l22.94.14" />
          <path
            d="M69.45 129.11l12.95-.14"
            marker-end="url(#a)"
            transform="translate(-32.26 -93.94)"
          />
        </g>
      </symbol>

      <symbol id="referred-out" viewBox="0 0 82 80">
        <!-- raw is referred-out.svg, simplified using https://jakearchibald.github.io/svgomg/ and then
      manually tweaked
        -->
        <defs>
          <marker orient="auto" id="b" overflow="visible">
            <path d="M4.62 0L-2.3 4v-8z" fill="white" />
          </marker>
        </defs>
        <g transform="translate(-46.68 -77.62)">
          <ellipse
            ry="7.25"
            rx="7.25"
            cy="109.79"
            cx="59.42"
            fill="none"
            stroke="white"
            stroke-width="3.7"
          />
          <path
            d="M46.68 131.67a12.5 12.5 0 0 1 12.46-12.5 12.5 12.5 0 0 1 12.54 12.43l-12.5.08z"
          />
        </g>
        <g transform="translate(24.16 -53.09) scale(.52727)">
          <ellipse
            cx="59.42"
            cy="109.79"
            rx="7.25"
            ry="7.25"
            fill="none"
            stroke="white"
            stroke-width="3.7"
          />
          <path
            d="M46.68 131.67a12.5 12.5 0 0 1 12.46-12.5 12.5 12.5 0 0 1 12.54 12.43l-12.5.08z"
          />
        </g>
        <g transform="translate(24.16 -22.22) scale(.52727)">
          <ellipse
            ry="7.25"
            rx="7.25"
            cy="109.79"
            cx="59.42"
            fill="none"
            stroke="white"
            stroke-width="3.7"
          />
          <path
            d="M46.68 131.67a12.5 12.5 0 0 1 12.46-12.5 12.5 12.5 0 0 1 12.54 12.43l-12.5.08z"
          />
        </g>
        <g transform="translate(24.16 8.82) scale(.52727)">
          <ellipse
            cx="59.42"
            cy="109.79"
            rx="7.25"
            ry="7.25"
            fill="none"
            stroke="white"
            stroke-width="3.7"
          />
          <path
            d="M46.68 131.67a12.5 12.5 0 0 1 12.46-12.5 12.5 12.5 0 0 1 12.54 12.43l-12.5.08z"
          />
        </g>
        <g fill="none" stroke="white" stroke-width="2">
          <path d="M25 23.41c5.75-8.36 12.44-11.71 23.65-13.26" />
          <path
            d="M69.45 113.03l12.92-1.94"
            marker-end="url(#b)"
            transform="translate(-7.6 -104.74)"
          />
        </g>
        <g fill="none" stroke="white" stroke-width="2">
          <path d="M25 57.04C30.81 65.4 37.5 68.75 48.71 70.3" />
          <path
            d="M69.45 113.03l12.92-1.94"
            marker-end="url(#b)"
            transform="matrix(1 0 0 -1 -7.6 185.2)"
          />
        </g>
        <g fill="none" stroke="white" stroke-width="2">
          <path d="M25 40.35l22.94.14" />
          <path
            d="M69.45 129.11l12.95-.14"
            marker-end="url(#b)"
            transform="translate(-7.6 -88.87)"
          />
        </g>
      </symbol>
    </defs>
  </svg>
</template>

<script>
// https://rastasheep.github.io/ionicons-sprite/#usage-how
export default {};
</script>

<style>
/* must NOT be scoped! */
.ion {
  display: inline-block;
  width: 32px;
  height: 32px;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.ion-small {
  display: inline-block;
  width: 20px;
  height: 20px;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.ion-1em {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}
.ion-2em {
  display: inline-block;
  width: 2em;
  height: 2em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}
.ion-3em {
  display: inline-block;
  width: 3em;
  height: 3em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}
</style>
