<template>
  <div
    style="height: 100vh"
    class="m-4 d-flex flex-column justify-content-between"
  >
    <h3>Frequently asked questions</h3>
    <br />
    <section
      id="middleScrolling"
      class="scrolling-section iphone-scroll-fudge"
      style="height: 100%; overflow-y: auto"
    >
      <b-button v-b-toggle.collapse-login variant="primary"
        >Why do I need to log in?</b-button
      >
      <b-collapse id="collapse-login" class="pt-2">
        <p>
          A neural network is only as good as the experts that trained it.
          End-users need to know whose opinions have been turned into a computer
          program.
        </p>
      </b-collapse>

      <br />
      <br />
      <b-button v-b-toggle.collapse-google variant="primary"
        >Why use Google?</b-button
      >
      <b-collapse id="collapse-google" class="pt-2">
        <p>The alternative to using a login provider would be to:</p>
        <ul>
          <li>Force you to create a username and password</li>
          <li>Maintain a complete password system at UnityImaging.net</li>
          <li>
            Risk that many people will re-use passwords, leaving themselves open
            to identity theft
          </li>
          <li>Provide staff to help people who forget their passwords</li>
          <li>
            Somehow not allow others to pretend they are you and have just
            forgotten the password
          </li>
        </ul>
        <p>
          These are far from trivial tasks. Federated login providers, such as
          Google, provide this service to cutting-edge security standards,
          professionally and cost-effectively.
        </p>
      </b-collapse>
      <br />
      <br />
      <b-button v-b-toggle.collapse-what-info variant="primary"
        >What information am I giving?</b-button
      >
      <b-collapse id="collapse-what-info" class="pt-2">
        <p>
          Before you sign in using Google, you can see <em>exactly</em> what
          information Google will tell us.
        </p>

        <p>
          Inspirion makes the minimal-est request permissible for Google signin,
          which is email address, and automatically receives (even though we
          don't want it) name, language preference and profile picture.
        </p>

        <p>To avoid revealing even that, see 'Extreme Privacy' below.</p>
      </b-collapse>

      <br /><br />
      <b-button v-b-toggle.collapse-extreme variant="primary"
        >Extreme privacy in 1 minute?</b-button
      >

      <b-collapse id="collapse-extreme" class="pt-2">
        <p>
          If you want to avoid disclosing
          <em>any</em> aspect of your true identity, you can create a blank
          Google login, purely for this purpose.
        </p>
        <h5>Step 1. After clicking the Google logo, choose Create Account</h5>
        <img
          class="pic"
          src="https://firebasestorage.googleapis.com/v0/b/inspirion-33fec.appspot.com/o/assets%2F5f9f75665f7c7a6efe8f48b49688cebb7d5d3268.png?alt=media&token=57a240e5-3354-4d54-8e3b-ef928255e78b"
        />
        <br />
        <br />
        <h5>Step 2. Make up any old name, username and password</h5>
        <img
          class="pic"
          src="https://firebasestorage.googleapis.com/v0/b/inspirion-33fec.appspot.com/o/assets%2F686dae58bc1377411ecb8b4a21a49aafdd075b4f.png?alt=media&token=89455fb5-677c-40d2-b7f2-52673e3f6242"
        />
        <p></p>
        <h5>
          Step 3. Leave phone number <em>blank</em>. Give any date of birth in
          2000.
        </h5>
        <img
          class="pic"
          src="https://firebasestorage.googleapis.com/v0/b/inspirion-33fec.appspot.com/o/assets%2F740d111cf55bd7d89d7a96dbe7da4e2e5b8dc8d2.png?alt=media&token=ac622107-e33e-4058-a4ec-a9e38c915277"
        />
        <p></p>
        <h5>Done!</h5>
        <p>
          If you forget the email and password you created, you will not be able
          to access your previous account. That is the nature of extreme
          privacy. Just create a new identity and carry on.
        </p>
      </b-collapse>
      <br />
      <br />
      <br />
      <p>
        <b-button variant="dark" @click="$emit('ok')">
          <svg class="ion chevron">
            <use xlink:href="#ion-chevron-left" /></svg
          >Back to Login page</b-button
        >
      </p>
    </section>
    <br /><br />
    <br /><br />
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      // Actual Data
      // .. put here
      // Making global consts and functions available to THIS
    };
  },
  computed: {},
  created() {},
  methods: {
    //
    //  ______ _          _
    //  |  ___(_)        | |
    //  | |_   _ _ __ ___| |__   __ _ ___  ___
    //  |  _| | | '__/ _ \ '_ \ / _` / __|/ _ \
    //  | |   | | | |  __/ |_) | (_| \__ \  __/
    //  \_|   |_|_|  \___|_.__/ \__,_|___/\___|
    //

    //
    //   _   _                            _   _
    //  | | | |                          | | (_)
    //  | | | |___  ___ _ __    __ _  ___| |_ _  ___  _ __
    //  | | | / __|/ _ \ '__|  / _` |/ __| __| |/ _ \| '_ \
    //  | |_| \__ \  __/ |    | (_| | (__| |_| | (_) | | | |
    //   \___/|___/\___|_|     \__,_|\___|\__|_|\___/|_| |_|
    //

    //
    //  ______                         _
    //  |  ___|                       | |
    //  | |_ ___  _ __ _ __ ___   __ _| |_
    //  |  _/ _ \| '__| '_ ` _ \ / _` | __|
    //  | || (_) | |  | | | | | | (_| | |_
    //  \_| \___/|_|  |_| |_| |_|\__,_|\__|
    //

    //   _   _             _             _   _
    //  | \ | |           (_)           | | (_)
    //  |  \| | __ ___   ___  __ _  __ _| |_ _  ___  _ __
    //  | . ` |/ _` \ \ / / |/ _` |/ _` | __| |/ _ \| '_ \
    //  | |\  | (_| |\ V /| | (_| | (_| | |_| | (_) | | | |
    //  \_| \_/\__,_| \_/ |_|\__, |\__,_|\__|_|\___/|_| |_|
    //                        __/ |
    //                       |___/

    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.pic {
  max-width: 20em;
  max-height: 20em;
  border: 1px solid lightgray;
}
</style>
