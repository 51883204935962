


<template>
  <div class="m-4 d-flex flex-column justify-content-between">
    <section
      id="middleScrolling"
      class="scrolling-section iphone-scroll-fudge"
      style="height: 100%; overflow-y: auto"
    >
      <br />
      <h4>
        Solving sign-in issues
        <sup style="font-size: 1em"
          ><b-button
            size="sm"
            variant="light"
            class="py-0 px-1"
            @click="$emit('ok')"
            >Close</b-button
          >
        </sup>
      </h4>
      <p>Which type of device are you using?</p>
      <b-button v-b-toggle.collapse-ios class="mt-2" variant="primary"
        >iPhone or iPad</b-button
      >
      <b-collapse id="collapse-ios" class="pt-2">
        <ol>
          <li>
            Go to Settings, General, Software Update. If an update is available,
            install it.
          </li>
          <li>
            If it still doesn't work, send a screen-shot of your "Software
            Update" screen as a Twitter Direct Message to @ProfDFrancis.
          </li>
        </ol>
      </b-collapse>
      <br />

      <b-button v-b-toggle.collapse-android class="mt-2" variant="primary"
        >Android phone or tablet</b-button
      >
      <b-collapse id="collapse-android" class="pt-2">
        <ol>
          <li>
            Go to Settings, Software Update and click Download and Install. If
            an update is available, install it.
          </li>
          <li>
            If it still doesn't work, send a screen-shot of your "Software
            Update" screen as a Twitter Direct Message to @ProfDFrancis.
          </li>
        </ol>
      </b-collapse>

      <br />

      <b-button v-b-toggle.collapse-mac class="mt-2" variant="primary"
        >Mac</b-button
      >
      <b-collapse id="collapse-mac" class="pt-2">
        <ol>
          <li>Try in Safari and in Google Chrome.</li>
          <li>
            In the Apple menu, choose System Preferences, then Software Update
          </li>
          <li>
            If updates are available, click Update Now. Then try again in
            Safari.
          </li>
          <li>
            If it still doesn't work, send a screen-shot of your "Software
            Update" screen as a Twitter Direct Message to @ProfDFrancis.
          </li>
        </ol>
      </b-collapse>
      <br />
      <b-button v-b-toggle.collapse-windows class="mt-2" variant="primary"
        >Windows or Linux</b-button
      >
      <b-collapse id="collapse-windows" class="pt-2">
        <ol>
          <li>
            Try in Google Chrome, if not already. (It does not work in Internet
            Explorer.)
          </li>
          <li>
            Make sure your Google Chrome is up to date:
            <ul>
              <li>Look at the triple-dot icon at the top right.</li>
              <li>Does it have the word "Update" immediately to its left?</li>
              <li>If so, click to update.</li>
            </ul>
          </li>

          <li>
            If it still doesn't work, go to the Triple-dot menu, choose Help,
            About Google Chrome, and send a screenshot of the result as a
            Twitter Direct Message to @ProfDFrancis.
          </li>
        </ol>
      </b-collapse>
    </section>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      // Actual Data
      // .. put here
      // Making global consts and functions available to THIS
    };
  },
  computed: {},
  created() {},

  methods: {
    //
    //  ______ _          _
    //  |  ___(_)        | |
    //  | |_   _ _ __ ___| |__   __ _ ___  ___
    //  |  _| | | '__/ _ \ '_ \ / _` / __|/ _ \
    //  | |   | | | |  __/ |_) | (_| \__ \  __/
    //  \_|   |_|_|  \___|_.__/ \__,_|___/\___|
    //

    //
    //   _   _                            _   _
    //  | | | |                          | | (_)
    //  | | | |___  ___ _ __    __ _  ___| |_ _  ___  _ __
    //  | | | / __|/ _ \ '__|  / _` |/ __| __| |/ _ \| '_ \
    //  | |_| \__ \  __/ |    | (_| | (__| |_| | (_) | | | |
    //   \___/|___/\___|_|     \__,_|\___|\__|_|\___/|_| |_|
    //

    //
    //  ______                         _
    //  |  ___|                       | |
    //  | |_ ___  _ __ _ __ ___   __ _| |_
    //  |  _/ _ \| '__| '_ ` _ \ / _` | __|
    //  | || (_) | |  | | | | | | (_| | |_
    //  \_| \___/|_|  |_| |_| |_|\__,_|\__|
    //

    //   _   _             _             _   _
    //  | \ | |           (_)           | | (_)
    //  |  \| | __ ___   ___  __ _  __ _| |_ _  ___  _ __
    //  | . ` |/ _` \ \ / / |/ _` |/ _` | __| |/ _ \| '_ \
    //  | |\  | (_| |\ V /| | (_| | (_| | |_| | (_) | | | |
    //  \_| \_/\__,_| \_/ |_|\__, |\__,_|\__|_|\___/|_| |_|
    //                        __/ |
    //                       |___/

    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.pic {
  max-width: 20em;
  max-height: 20em;
  border: 1px solid lightgray;
}
</style>
