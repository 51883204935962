<template>
  <div
    id="app"
    class="w-100 m-0 p-0 d-flex flex-column"
    style="position: relative"
  >
    <!-- This is at the behest of LIGHTHOUSE -->
    <link rel="preconnect" href="https://storage.googleapis.com" />

    <section
      v-if="loggedState === undefined"
      id="very-early-loading-message"
      style="
        z-index: -1000;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        color: gray;
      "
      class="d-flex flex-row justify-content-center align-items-center"
    >
      <!-- A very early display so people are confident they are on the right site, even though address bar isn't UnityImaging any more -->
      <!-- Unlike the logo, which (a) requires us to know we are OK to install on this device and (b) needs the image itself to load,
      this can display immediately. The only way to be earlier is to be in "index.html" but editing that, rather than the Vue JS Html, feels wrong.-->
      <h2 style="padding-top: 1em; text-align: center">UnityImaging.net</h2>
    </section>

    <section v-if="amOnline === false" style="position: absolute">
      <AppOfflineWarning></AppOfflineWarning>
    </section>

    <section
      v-for="z in [-9000, 9000]"
      :key="z"
      :style="`position:absolute; top: 0; left: 0; z-index:${z}; pointer-events:none; height:100vh; width:100vw;`"
      class="d-flex flex-column justify-content-end"
    >
      <div
        class="mb-2 d-flex flex-row align-items-end"
        style="color: #404040; font-size: 50%"
      >
        <b-button
          class="mx-2"
          style="pointer-events: auto; font-size: 100%"
          variant="outline-dark"
          size="sm"
          @click="getNewest"
        >
          Restart
          <br />app
        </b-button>

        <div class="small">
          <div v-if="z < 0" style="line-height: 1">
            if stuck on
            <br />blank screen
          </div>
          <small>Status: {{ $route.fullPath }}</small>
          <br />
          <span v-if="rebooting" style="color: blue">{{ rebooting }}</span>
        </div>
      </div>
    </section>

    <!-- Option 1. Bootup installation screen -->
    <section v-if="deviceToPromptInstallation !== 'OK'" class="h-100 v-100">
      <Installations
        :device="device"
        :deviceToPromptInstallation="deviceToPromptInstallation"
      ></Installations>
    </section>

    <section
      v-if="deviceToPromptInstallation === 'OK' && loggedState === undefined"
      class="center-container"
    >
      <img
        style="max-width: 70vw; max-height: 70vh"
        src="./assets/logo.png"
        alt="Logo"
      />
    </section>

    <!-- Option 2. We know for sure not logged in. -->
    <section
      v-if="deviceToPromptInstallation === 'OK' && loggedState === 'out'"
    >
      <!-- <div style="color:black;" class="m-5">
        App Initialisation Phase
        <span style="font-size:300%;">2</span>
      </div>-->
      <Login :auth="auth"></Login>
    </section>

    <!-- Option 3. Logged in, so use Router -->
    <section v-if="deviceToPromptInstallation === 'OK' && loggedState === 'in'">
      <!-- Option 3A. If got the user data in, we can really show the pages, otherwise we have to show spinner -->
      <div
        v-if="
          user.privilegesIsLoaded &&
          user.contactIsLoaded &&
          user.presenceIsLoaded
        "
      >
        <TransitionPage>
          <!-- use OVERFLOW HIDDEN for rigid, non-scrolling pages, but OVERFLOW-Y:SCROLL for scrolling -->
          <router-view :style="scrollingStyle" :user="user"></router-view>
        </TransitionPage>
      </div>

      <div v-else>
        <!-- Option 3B. Logged in, but data not loaded, so show spinner -->
        <div
          class="d-flex flex-row"
          style="width: 100%; justify-content: space-evenly; opacity: 0.2"
        >
          <div>{{ user.presenceIsLoaded ? "■" : "□" }}</div>
          <div>
            {{ user.contactIsLoaded ? "■" : "□" }}
          </div>
          <div>{{ user.privilegesIsLoaded ? "■" : "□" }}</div>
        </div>

        <Spinner></Spinner>
      </div>
    </section>

    <section>
      <AppAutoupdate :onlyShowCopyright="false"></AppAutoupdate>
    </section>

    <!-- Zero-size component which creates the SVG icon *definitions*, so they can be used  in any components  -->
    <Icons></Icons>
  </div>
</template>

<script>
// const randHex = Math.round(Math.random() * 2 ** 32).toString(16); // Used for cacheBusting

import * as bowser from "bowser";

// Pages
import Login from "./views/Login.vue";
import TransitionPage from "./components/TransitionPage.vue"; // Enables page transitions

// Components of page
import Installations from "./components/Installations.vue";
import AppOfflineWarning from "./components/AppOfflineWarning.vue";
const amOnlineSecondsBeforeCommenting = 10; // Listed here because acted upon here in the App, not in the component
import AppAutoupdate from "./components/AppAutoupdate.vue";

// Odd bits
import Icons from "./components/Icons.vue"; // Explicitly contains SVG icons

// From external sources
import Spinner from "./components/ext/Spinner.vue";

import {
  // Non- "this."
  auth,
  //  systemStatusRef,
  amOnlineRef,
  ServerValueTIMESTAMP,

  // DATABASE branches accessed for ordinary users. (Staff-only database branches opened elsewhere)

  // 1. User-based (each user has his own branch)
  contactRootRef,
  privilegesRootRef,
  presenceRootRef,
} from "./utils/firebase.js";

import { getNewest } from "./utils/getNewest.js"; // but remember to also re-export it in methods

export default {
  name: "Unity",

  components: {
    Installations,
    AppAutoupdate,
    AppOfflineWarning,
    Spinner,
    Icons,
    Login,
    TransitionPage,
  },
  data() {
    return {
      rebooting: "",
      device: {},
      isLocalHost: false,
      standalone: false,
      deviceToPromptInstallation: "startup",

      // Firebase
      auth: auth, // This makes "AUTH" from firebase available to the template as "(this.)auth", so it can be passed as props to children such as Login.vue

      jsActiveLongEnoughToTellOnlineStatus: false,
      loggedState: undefined,
      amOnline: undefined,

      user: {
        id: null,
        fromAuth: {}, // We will copy in the USER object from Auth, into here.

        // ----- PRESENCE
        // List the branches we want to be reactive to. For each there is a REFERENCE, an OBJECT, and an ISLOADED
        presenceRef: null,
        presenceObj: {},
        presenceIsLoaded: false,

        // ----- PRIVILEGES
        privilegesRef: null,
        privilegesObj: {},
        privilegesIsLoaded: false,

        // ----- CONTACT
        contactRef: null,
        contactObj: { view: "" },
        contactIsLoaded: false,
      },
    };
  },

  computed: {
    scrollingStyle() {
      if ((this.$route.name || "").startsWith("Audit")) {
        return "overflow-y:scroll;";
      } else {
        return "overflow: hidden;";
      }
    },
  },

  created() {
    this.readDevice();
    this.readStandaloneStatus();
    this.takeActionBasedOnDeviceAndStandalone();

    if (!this.standalone) {
      setInterval(() => {
        // Every 2 seconds, check standalone. This is needed because CHROME does not restart the app when it installs on desktop: it just transfers its existence to the app. So need to keep checking
        this.readStandaloneStatus();
        this.takeActionBasedOnDeviceAndStandalone();
      }, 2000);
    }

    auth.onAuthStateChanged(user =>
      this.updateLoggedStateP(user).then(() => {
        // Nothing actually to do
      })
    );
  },

  methods: {
    async updateLoggedStateP(userFromAuth) {
      // Have to use this type of function definition and not fat arrow, because fat arrow fails to make "this" equal to the Vue instance

      if (userFromAuth) {
        await this.userHasLoggedIn(userFromAuth);
        this.loggedState = "in";
      } else {
        this.loggedState = "out";
      }
    },

    async userHasLoggedIn(userFromAuth) {
      this.password = ""; // erase password
      this.user.fromAuth = userFromAuth;
      this.user.id = userFromAuth.uid;

      contactRootRef.child(userFromAuth.uid).update({
        displayName: userFromAuth.displayName,
        email: userFromAuth.email,
      });

      //  ##     ##  ######  ######## ########
      //  ##     ## ##    ## ##       ##     ##
      //  ##     ## ##       ##       ##     ##
      //  ##     ##  ######  ######   ########
      //  ##     ##       ## ##       ##   ##
      //  ##     ## ##    ## ##       ##    ##
      //   #######   ######  ######## ##     ##
      // Load firebase stuff into the branches of "user"

      // In the end I did this instead of vueFire, for these variables. But elsewhere (and in future) I use VUEFIRE (the official VUE JS version) because it does clever auto-unbinding etc.

      // 1. Contact. Bind and update
      this.bindBranchAsRefAndObjAndIsLoaded(
        this.user,
        "contact",
        contactRootRef.child(this.user.id)
      );

      await this.user.contactRef
        .child("emailVerified")
        .set(userFromAuth.emailVerified); // This is also done "live" in the MyAccount module in the button where the user says he has just clicked the link.

      // 2. Privileges. Bind (can't write)
      this.bindBranchAsRefAndObjAndIsLoaded(
        this.user,
        "privileges",
        privilegesRootRef.child(this.user.id)
      );

      // 3. Presence. Bind and update
      this.bindBranchAsRefAndObjAndIsLoaded(
        this.user,
        "presence",
        presenceRootRef.child(this.user.id)
      );
      await this.user.presenceRef.update({
        dLastLogin: ServerValueTIMESTAMP,
        emailAtLastLogin: userFromAuth.email,
      });

      amOnlineRef.on("value", async snapshot => {
        if (snapshot.val()) {
          const instanceId =
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15);

          this.user.presenceRef.onDisconnect().update({
            lastChangeWasOn: null,
            lastWentOff: ServerValueTIMESTAMP,
            ["currentSessions/" + instanceId]: null,
          });

          await this.user.presenceRef
            .update({
              lastChangeWasOn: true,
              ["currentSessions/" + instanceId]: true,
            })
            .then(() => {
              this.amOnline = true;
            })
            .catch(() => {});
        } else {
          this.amOnline = false;
        }
      });
      setTimeout(() => {
        this.jsActiveLongEnoughToTellOnlineStatus = true;
      }, amOnlineSecondsBeforeCommenting * 1000);
    },

    bindBranchAsRefAndObjAndIsLoaded(
      containerObject,
      keyPrefix,
      firebaseRef,
      options
    ) {
      //Use this to put userContact database into user.contactObj etc.
      // and then separately to put trialPublic database into trial.publicObj etc.
      // OPTIONS:
      //   addArray: true if you want an Array version as well as the usual Obj
      //   callBack: function that you want executed once data loaded

      //--------------------------------
      // CONTAINER OBJECT is either this.user or this.trial

      // "keyPrefix" can be, for example, "status"
      // That would create in the "this.user" object, the following:
      //   this.user.statusRef    which would be the firebase Ref for the status
      //   this.user.statusObj    which would be the firebase-bound status
      //   this.user.statusIsLoaded    which would be true once read from firebase

      // Step 1. Open up the pathway to that point in the table
      if (!this.branchBindingTable) {
        this.branchBindingTable = {};
      }

      // Step 2. If this variable is already bound to a firebase node, unbind
      if (this.branchBindingTable[keyPrefix]) {
        // If this keyPrefix is already bound to a firebase node, unbind it. Otherwise updates to the OLD node will wrongly cause themselves to be read in and trigger an incorrect "update" to the new node, i.e. overwrite your new patient's data with the previous patient's data (just because the previous patient happened to update their data)
        this.branchBindingTable[keyPrefix].off();
        containerObject[keyPrefix + "IsLoaded"] = false;
      }

      // Step 3. Now bind to the new firebase node and store the three things, "REF","OBJ" itself, and "ISLOADED".
      this.branchBindingTable[keyPrefix] = firebaseRef;
      containerObject[keyPrefix + "Ref"] = firebaseRef;

      firebaseRef.on("value", snapshot => {
        // Every time firebase value changes (including the first where firebase just comes live) this will cause an Object.assign, causing a re-render of the object in this and the children components.

        let newKeyValuePairOrPairs = {};
        let newValueAsObj = snapshot.val() || {};
        newKeyValuePairOrPairs[keyPrefix + "Obj"] = newValueAsObj;
        if (options && options.addArray) {
          const newValueAsArray = Object.keys(newValueAsObj || {}).map(key => ({
            ...newValueAsObj[key],
            id: key,
          }));
          newKeyValuePairOrPairs[keyPrefix + "Array"] = newValueAsArray;
        }
        Object.assign(containerObject, newKeyValuePairOrPairs);

        containerObject[keyPrefix + "IsLoaded"] = true;
        if (options && options.callback) {
          options.callback();
        }
      });
    },

    // ______                   _           _        _ _
    // |  ___|                 (_)         | |      | | |
    // | |_ ___  _ __ ___ ___   _ _ __  ___| |_ __ _| | |
    // |  _/ _ \| '__/ __/ _ \ | | '_ \/ __| __/ _` | | |
    // | || (_) | | | (_|  __/ | | | | \__ \ || (_| | | |
    // \_| \___/|_|  \___\___| |_|_| |_|___/\__\__,_|_|_|

    readDevice() {
      // Dependency: npm install bowser
      // And in the imports of App.vue:
      //    import * as bowser from "bowser";

      const parser = bowser.getParser(window.navigator.userAgent);
      this.device = parser.parse().parsedResult;
      this.isLocalHost =
        /* insert "FALSE &&" here" to force installation screen on localhost */
        window.location.href.toLowerCase().includes("localhost");
      // zonsole.log(JSON.stringify(this.device));
    },

    readStandaloneStatus() {
      // Detect standalone state (PWA)
      this.standalone = false;
      if (window.matchMedia("(display-mode: standalone)").matches) {
        // Chromium-based
        this.standalone = true;
      }
      if (window.navigator && window.navigator.standalone) {
        // iOS
        this.standalone = true;
      }
    },

    takeActionBasedOnDeviceAndStandalone() {
      this.deviceToPromptInstallation = "OK";
      if (this.isLocalHost) {
        // Fudge to allow the app to be run as local host in screen-emulation mode for anything
        return;
      }

      if (
        this.device &&
        this.device.platform &&
        this.device.platform.type === "mobile" &&
        this.device.os &&
        this.device.os.name === "Android" &&
        window.location.href.toLowerCase().includes("unityimaging.net")
      ) {
        // Fudge because Android users get stuck in an endless loop of Google login if they go to unityImaging.net or scantensus.web.app
        // Something to do with Authentication that I can't figure out and this fix does seem to work, without needing them to type in something long and different.
        window.location.href = window.location.href.replace(
          /unityimaging\.net/gi,
          "scantensus.firebaseapp.com"
        );
      }

      if (
        this.device &&
        this.device.platform &&
        this.device.platform.type === "mobile" &&
        this.device.os &&
        this.device.os.name === "Android" &&
        this.standalone === false
      ) {
        if (
          this.device.browser &&
          this.device.browser.name &&
          this.device.browser.name !== "Chrome"
        ) {
          this.deviceToPromptInstallation = "Android: go Chrome";
        } else {
          this.deviceToPromptInstallation = "Android: install";
        }
      } else if (
        this.device &&
        this.device.platform &&
        this.device.platform.type === "mobile" &&
        this.device.platform.model === "iPhone" &&
        this.standalone === false
      ) {
        if (
          this.device.browser &&
          this.device.browser.name &&
          this.device.browser.name !== "Safari"
        ) {
          this.deviceToPromptInstallation = "iPhone: go Safari";
        } else {
          this.deviceToPromptInstallation = "iPhone: install";
        }
      } else if (
        this.device &&
        this.device.platform &&
        this.device.platform.type === "tablet" &&
        this.device.platform.model === "iPad" &&
        this.standalone === false
      ) {
        if (
          this.device.browser &&
          this.device.browser.name &&
          this.device.browser.name !== "Safari"
        ) {
          this.deviceToPromptInstallation = "iPad: go Safari";
        } else {
          this.deviceToPromptInstallation = "iPad: install";
        }
      } else if (
        this.device &&
        this.device.os &&
        (this.device.os.name === "Windows" ||
          this.device.os.name === "macOS") &&
        this.device.browser &&
        this.device.browser.name &&
        this.device.browser.name !== "Chrome"
      ) {
        this.deviceToPromptInstallation = "go Chrome";
      } else if (
        this.device &&
        this.device.os &&
        (this.device.os.name === "Windows" ||
          this.device.os.name === "macOS") &&
        this.device.browser &&
        this.device.browser.name &&
        this.device.browser.name === "Chrome" &&
        this.standalone === false
      ) {
        this.deviceToPromptInstallation = "install";
      }
      // EITHER PWA of iPhone, iPad, Android or Windows_Chrome, macOS_Chrome OR any other computer including Linux etc
    },

    getNewest() {
      // a THIS function that calls a non-THIS function:
      this.rebooting = "rebooting";
      console.warn("Getting newest");
      getNewest();
    },
  },
};
</script>

<style lang="scss">
/* Do not "SCOPE" these as we do want them globally available */

// Putting BOTH "?display=swap" into the google font web address, AND "font-display:swap" into the CSS
// Actually probably only one is needed but just doing belt-and-braces

@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@200;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap");
// Prefer Source Sans to open sans because it distinguishes I from l and 1 better.
#app {
  font-family: "Source Sans Pro", "Segoe UI", "Segoe", Helvetica, Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  margin: 0.5rem;
  font-display: swap;
}

@media print {
  // To allow the Audit page to print yellow rectangles (otherwise they require the user to click Print Background Graphics)
  body {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

.internal-code {
  font-family: "Source Code Pro", "Courier New", Courier, monospace;
}

.page-scroll-y {
  height: 100vh;
  overflow-y: auto; /* overflow-y:scroll works but creates ugly dimmed bars on Chrome and Edge, whenever there is too little data to need a scroll bar */
  -webkit-overflow-scrolling: touch;
}

// SCROLLING -----------------------------------

.scrolling-section {
  -webkit-overflow-scrolling: touch; // This is the IPHONE SCROLL FUDGE
  height: 100%;
  overflow-y: auto;
  padding-right: 2px;
}

// Narrow scroll bar. Has to be in the "global" space, not within the ".scrolling-section"

::-webkit-scrollbar {
  width: 10px;
  height: 10px; // In case a sideways scroll is ever needed
}

::-webkit-scrollbar-track {
  background-color: none;
}

::-webkit-scrollbar-thumb {
  background-color: lightgray;
}

// End SCROLLING ---------------------------------

.unselectable {
  /* https://gist.github.com/23maverick23/64b3b587c88697558fac */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

.selectable {
  -webkit-touch-callout: auto;
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
  pointer-events: auto;
}

.selectable-text {
  -webkit-touch-callout: default;
  -webkit-user-select: default;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  pointer-events: auto;
}

.translucent-pane {
  background-color: #00007060;
  color: white;
  border-radius: 1em;
  pointer-events: none;
}

.button-class {
  pointer-events: auto;
}

.small-button-class {
  font-size: 71%;
  padding-left: 0.2em;
  padding-right: 0.2em;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
}
.header-logged-in {
  margin: 0;
  height: 8vh;
  padding: 0 16px 0 24px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.center-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.link {
  font-size: smaller;
  color: royalblue;
}

/* general utility classes for project */

.ellipsis-must-set-width {
  /* apply this class to a div to set ellipsis truncation but you MUST set a width on the element, and the text mUST be the immediate contents of the DIV, not of children.
  A good way to set the width is
    width: calc( 80% - 32px or whatever);
    The space before/after the "-" is mandatory
  */
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

.officeUseOnly {
  font-size: 50%;
  font-weight: 100;
  color: lightgray;
}

pre {
  font-family: "Courier New", monospace, sans-serif;
}

//

.swish-enter,
.swish-leave-to {
  opacity: 0;
  max-height: 0;
}

.swish-leave,
.swish-enter-to {
  opacity: 1;
  max-height: 100vh;
}

.swish-enter-active {
  transition: max-height 1s, opacity 1s;
}

.swish-leave-active {
  transition: max-height 1s, opacity 2s;
}

//

.swish-2d-enter,
.swish-2d-leave-to {
  opacity: 0;
  max-height: 0;
  max-width: 0;
}
.swish-2d-leave,
.swish-2d-enter-to {
  opacity: 1;
  max-height: 100vh;
  max-width: 100vw;
}

.swish-2d-enter-active {
  transition: max-height 1s, max-width 1s, opacity 1s;
}

.swish-2d-leave-active {
  transition: max-height 1s, max-width 1s, opacity 2s;
}

//

.blinking {
  /* https://stackoverflow.com/questions/16344354/how-to-make-blinking-flashing-text-with-css-3 */
  animation: blinking-keyframes 1s linear infinite;
}

@keyframes blinking-keyframes {
  50% {
    opacity: 0;
  }
}

.v-spacer {
  min-height: 0.7em;
}

.help {
  vertical-align: super;
  font-size: 71%;
  color: blue;
}

.help-sub {
  vertical-align: sub;
  font-size: 71%;
  color: blue;
}
</style>
