<template>
  <section
    style="color: black"
    class="
      w-100
      h-100
      selectable-text
      d-flex
      flex-column
      justify-content-center
      align-content-center align-items-center
    "
  >
    <div class="w-50">
      <p
        v-if="
          device &&
          device.os &&
          device.os.name === 'macOS' &&
          device &&
          device.platform &&
          device.platform.type === 'desktop'
        "
      >
        Your device says it is a <strong>Mac computer</strong>. If it is really
        an iPad or iPhone, go to its Settings app &gt; Safari &gt; Request
        Desktop Website, and turn off the "All websites" option. Then click
        "Restart app" at the bottom left of this app.
      </p>
    </div>

    <div v-if="deviceToPromptInstallation === 'Android: go Chrome'">
      <div
        class="
          jumbotron
          d-flex
          flex-row
          justify-content-center
          align-items-center
        "
        style="height: 50vh; color: black"
      >
        <h1>
          Please use
          <strong>Google Chrome</strong>
          <br />to access this app.
        </h1>
      </div>
    </div>
    <div v-else-if="deviceToPromptInstallation === 'Android: install'">
      <!-- in VUE, the "/" means copy direct from the "public" folder (outside the "src" folder) -->
      <InstallationAndroid
        :device="device"
        :deviceToPromptInstallation="deviceToPromptInstallation"
      ></InstallationAndroid>
    </div>
    <div v-else-if="deviceToPromptInstallation === 'iPhone: go Safari'">
      <div
        class="
          jumbotron
          d-flex
          flex-row
          justify-content-center
          align-items-center
        "
        style="height: 50vh; color: black"
      >
        <h1>Please use Safari</h1>
      </div>
    </div>
    <div v-else-if="deviceToPromptInstallation === 'iPhone: install'">
      <!-- in VUE, the "/" means copy direct from the "public" folder (outside the "src" folder) -->
      <InstallationIphone
        :device="device"
        :deviceToPromptInstallation="deviceToPromptInstallation"
      ></InstallationIphone>
    </div>
    <div v-else-if="deviceToPromptInstallation === 'iPad: go Safari'">
      <div
        class="
          jumbotron
          d-flex
          flex-row
          justify-content-center
          align-items-center
        "
        style="height: 50vh; color: black"
      >
        <h1>Please use Safari</h1>
      </div>
    </div>
    <div v-else-if="deviceToPromptInstallation === 'iPad: install'">
      <InstallationIpad
        :device="device"
        :deviceToPromptInstallation="deviceToPromptInstallation"
      ></InstallationIpad>
    </div>
    <div v-else-if="deviceToPromptInstallation === 'go Chrome'">
      <div
        class="
          jumbotron
          d-flex
          flex-row
          justify-content-center
          align-items-center
        "
        style="height: 50vh; color: black"
      >
        <h1>
          Please use
          <strong>Google Chrome</strong>
          <br />to access this app.
        </h1>
      </div>
    </div>
    <div v-else-if="deviceToPromptInstallation === 'install'" class="w-100">
      <InstallationChrome
        :device="device"
        :deviceToPromptInstallation="deviceToPromptInstallation"
      ></InstallationChrome>
    </div>
    <div
      v-else-if="deviceToPromptInstallation === 'startup'"
      style="color: gray"
    >
      <Spinner></Spinner>
    </div>
    <div v-else class="w-50">
      <!-- This will show up if the device is something different -->
      <h5>Sorry, we have not made a version for this combination.</h5>
      <h5>Platform: {{ device.platform.type }}</h5>
      <h5>OS: {{ device.os.name }} {{ device.os.version }}</h5>
      <h5>Browser: {{ device.browser.name }} {{ device.browser.version }}</h5>
    </div>
    <AppAutoupdate :onlyShowCopyright="true"></AppAutoupdate>
  </section>
</template>

<script>
import InstallationChrome from "./InstallationChrome.vue";
import InstallationAndroid from "./InstallationAndroid.vue";
import InstallationIphone from "./InstallationIphone.vue";
import InstallationIpad from "./InstallationIpad.vue";
import AppAutoupdate from "./AppAutoupdate.vue";

export default {
  props: {
    device: { type: Object, required: true },
    deviceToPromptInstallation: { type: String, required: true },
  },

  components: {
    InstallationChrome,
    InstallationAndroid,
    InstallationIphone,
    InstallationIpad,
    AppAutoupdate,
  },

  methods: {
    cacheBust(rawUrl) {
      let joiningChar;
      if (rawUrl.includes("?")) {
        joiningChar = "&";
      } else {
        joiningChar = "?";
      }
      const appendix = joiningChar + "imageCacheBust=1";
      const resultUrl = rawUrl + appendix;

      return resultUrl;
    },
  },
};
</script>

<style>
</style>