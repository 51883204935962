import Vue from "vue";
import Router from "vue-router";

// PAGES, of two types

// Pages a first-time user needs, so load them non-lazily
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import ProjectClassDispatcher from "@/views/ProjectClassDispatcher.vue";

// Pages a first-time user doesn't need, so lazy loaded
// https://router.vuejs.org/guide/advanced/lazy-loading.html
const Fiducial = () => import("@/views/Fiducial.vue");
const AuditFiducial = () => import("@/views/AuditFiducial.vue");
const AuditTemporal = () => import("@/views/AuditTemporal.vue");
const Compare = () => import("@/views/Compare.vue");
const CompareConclusion = () => import("@/views/CompareConclusion.vue");
const Temporal = () => import("@/views/Temporal.vue");
const Manage = () => import("@/views/Manage.vue");
const ProjectChooser = () => import("@/views/ProjectChooser.vue");
const ProjectCreate = () => import("@/views/ProjectCreate.vue");
const ProjectConfig = () => import("@/views/ProjectConfig.vue");
const ProjectAccess = () => import("@/views/ProjectAccess.vue");
const ProjectStatus = () => import("@/views/ProjectStatus.vue");
const ProjectCyber = () => import("@/views/ProjectCyber.vue");
const Privacy = () => import("@/views/Privacy.vue");

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    { path: "/index.html", redirect: "/" }, // iPHONE: Solves White Screen of Death caused by the App Icon being attached to "/index.html"
    // { path: "index.html", redirect: "/" }, // ANDROID: Solves White Screen of Death caused by the App Icon being attached to "index.html"
    {
      path: "/",
      name: "Home",
      component: Home,
      meta: {
        sliderPath: "",
        gracePeriodMinutesBeforeAutoUpdate: 0,
      },
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: {
        sliderPath: "",
        gracePeriodMinutesBeforeAutoUpdate: 0,
      },
    },

    {
      path: "/fiducial/:view",
      name: "Fiducial",
      component: Fiducial,
      props: true,
      // What props:true does is allow you to send  via this.$router.push({params:{xxx:123}}), and pick up as prop this.xxx=123 in the  component
      meta: {
        sliderPath: "[R]",
        gracePeriodMinutesBeforeAutoUpdate: 15,
      },
    },
    {
      path: "/audit-fiducial/:view",
      name: "AuditFiducial",
      component: AuditFiducial,
      props: true,
      meta: {
        sliderPath: "[R][R]",
        gracePeriodMinutesBeforeAutoUpdate: 15,
      },
    },
    {
      path: "/audit-temporal/:view",
      name: "AuditTemporal", // this name prefix is specifically looked for in deciding whether to scroll or allow a long page
      component: AuditTemporal,
      meta: {
        sliderPath: "[R][R]",
        gracePeriodMinutesBeforeAutoUpdate: 15,
      },
    },
    {
      path: "/compare/:view",
      name: "Compare",
      component: Compare,
      props: true,
      meta: {
        sliderPath: "[R]",
        gracePeriodMinutesBeforeAutoUpdate: 55,
      },
    },
    {
      path: "/compareconclusion/:view",
      name: "CompareConclusion",
      component: CompareConclusion,
      props: true,
      meta: {
        sliderPath: "[R][R]",
        gracePeriodMinutesBeforeAutoUpdate: 0,
      },
    },
    {
      path: "/temporal/:view",
      name: "Temporal",
      props: true,
      component: Temporal,
      meta: {
        sliderPath: "[R]",
        gracePeriodMinutesBeforeAutoUpdate: 10,
      },
    },
    {
      path: "/manage/:view",
      name: "Manage",
      component: Manage,
      props: true,
      meta: {
        sliderPath: "[R][R]",
        gracePeriodMinutesBeforeAutoUpdate: 0,
      },
    },
    {
      path: "/projectchooser", // Router gets one version without a param
      name: "ProjectChooser",
      component: ProjectChooser,
      props: true,
      meta: {
        sliderPath: "",
        gracePeriodMinutesBeforeAutoUpdate: 0,
      },
    },
    {
      path: "/go/:requestedView", // And one with, to avoid a router error on the no-param calls
      name: "ProjectChooserDeeplink",
      component: ProjectChooser,
      props: true,
      meta: {
        sliderPath: "",
        gracePeriodMinutesBeforeAutoUpdate: 0,
      },
    },
    {
      path: "/create",
      name: "ProjectCreate",
      component: ProjectCreate,
      props: true,
      meta: {
        sliderPath: "",
        gracePeriodMinutesBeforeAutoUpdate: 1,
      },
    },
    {
      path: "/config/:view",
      name: "ProjectConfig",
      component: ProjectConfig,
      props: true,
      meta: {
        sliderPath: "[R][R][R]",
        gracePeriodMinutesBeforeAutoUpdate: 55,
      },
    },
    {
      path: "/access",
      name: "ProjectAccess",
      component: ProjectAccess,
      meta: {
        sliderPath: "[R][R][R]",
        gracePeriodMinutesBeforeAutoUpdate: 2,
      },
    },
    {
      path: "/status/:view",
      name: "ProjectStatus",
      component: ProjectStatus,
      props: true,
      meta: {
        sliderPath: "[R][R][R]",
        gracePeriodMinutesBeforeAutoUpdate: 2,
      },
    },
    {
      path: "/cyber/:view",
      name: "Cyber",
      component: ProjectCyber,
      props: true,
      meta: {
        sliderPath: "[R][R][R]",
        gracePeriodMinutesBeforeAutoUpdate: 55,
      },
    },
    {
      path: "/privacy",
      name: "Privacy",
      component: Privacy,
      meta: {
        sliderPath: "[R]",
        gracePeriodMinutesBeforeAutoUpdate: 0,
      },
    }, //   ____  _                 _       _
    //  |  _ \(_)___ _ __   __ _| |_ ___| |__   ___ _ __
    //  | | | | / __| '_ \ / _` | __/ __| '_ \ / _ \ '__|
    //  | |_| | \__ \ |_) | (_| | || (__| | | |  __/ |
    //  |____/|_|___/ .__/ \__,_|\__\___|_| |_|\___|_|
    //     _    / / |_| __   _(_) _____      __
    //   _| |_ / /____  \ \ / / |/ _ \ \ /\ / /
    //  |_   _/ /_____|  \ V /| |  __/\ V  V /
    //    |_|/_/          \_/ |_|\___| \_/\_/

    // If updating:
    //      _    _     ____   ___    _   _ ____  ____    _  _____ _____
    //     / \  | |   / ___| / _ \  | | | |  _ \|  _ \  / \|_   _| ____|
    //    / _ \ | |   \___ \| | | | | | | | |_) | | | |/ _ \ | | |  _|
    //   / ___ \| |___ ___) | |_| | | |_| |  __/| |_| / ___ \| | | |___
    //  /_/   \_\_____|____/ \___/   \___/|_|   |____/_/   \_\_| |_____|
    //  (_)_ __    / ___| |__   ___   ___  ___  ___ _ __ _
    //  | | '_ \  | |   | '_ \ / _ \ / _ \/ __|/ _ \ '__(_)
    //  | | | | | | |___| | | | (_) | (_) \__ \  __/ |   _
    //  |_|_|_|_|  \____|_|_|_|\___/_\___/|___/\___|_|  (_)
    //  ( | ) |      / \  | __ )| ____| |  ( | ) | |__ | |_ _ __
    //   V V| |     / _ \ |  _ \|  _| | |   V V  | '_ \| __| '_ \
    //      | |___ / ___ \| |_) | |___| |___     | |_) | |_| | | |
    //      |_____/_/   \_\____/|_____|_____|    |_.__/ \__|_| |_|

    {
      path: "/label",
      name: "ProjectClassDispatcherWithoutView",
      component: ProjectClassDispatcher,
      props: true,
      meta: {
        sliderPath: "",
        gracePeriodMinutesBeforeAutoUpdate: 0,
      },
    },
    {
      path: "/label/:view",
      name: "ProjectClassDispatcher",
      component: ProjectClassDispatcher,
      props: true,
      meta: {
        sliderPath: "",
        gracePeriodMinutesBeforeAutoUpdate: 0,
      },
    },
  ],
});
