var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"\n    w-100\n    h-100\n    selectable-text\n    d-flex\n    flex-column\n    justify-content-center\n    align-content-center align-items-center\n  ",staticStyle:{"color":"black"}},[_c('div',{staticClass:"w-50"},[(
        _vm.device &&
        _vm.device.os &&
        _vm.device.os.name === 'macOS' &&
        _vm.device &&
        _vm.device.platform &&
        _vm.device.platform.type === 'desktop'
      )?_c('p',[_vm._v(" Your device says it is a "),_c('strong',[_vm._v("Mac computer")]),_vm._v(". If it is really an iPad or iPhone, go to its Settings app > Safari > Request Desktop Website, and turn off the \"All websites\" option. Then click \"Restart app\" at the bottom left of this app. ")]):_vm._e()]),(_vm.deviceToPromptInstallation === 'Android: go Chrome')?_c('div',[_vm._m(0)]):(_vm.deviceToPromptInstallation === 'Android: install')?_c('div',[_c('InstallationAndroid',{attrs:{"device":_vm.device,"deviceToPromptInstallation":_vm.deviceToPromptInstallation}})],1):(_vm.deviceToPromptInstallation === 'iPhone: go Safari')?_c('div',[_vm._m(1)]):(_vm.deviceToPromptInstallation === 'iPhone: install')?_c('div',[_c('InstallationIphone',{attrs:{"device":_vm.device,"deviceToPromptInstallation":_vm.deviceToPromptInstallation}})],1):(_vm.deviceToPromptInstallation === 'iPad: go Safari')?_c('div',[_vm._m(2)]):(_vm.deviceToPromptInstallation === 'iPad: install')?_c('div',[_c('InstallationIpad',{attrs:{"device":_vm.device,"deviceToPromptInstallation":_vm.deviceToPromptInstallation}})],1):(_vm.deviceToPromptInstallation === 'go Chrome')?_c('div',[_vm._m(3)]):(_vm.deviceToPromptInstallation === 'install')?_c('div',{staticClass:"w-100"},[_c('InstallationChrome',{attrs:{"device":_vm.device,"deviceToPromptInstallation":_vm.deviceToPromptInstallation}})],1):(_vm.deviceToPromptInstallation === 'startup')?_c('div',{staticStyle:{"color":"gray"}},[_c('Spinner')],1):_c('div',{staticClass:"w-50"},[_c('h5',[_vm._v("Sorry, we have not made a version for this combination.")]),_c('h5',[_vm._v("Platform: "+_vm._s(_vm.device.platform.type))]),_c('h5',[_vm._v("OS: "+_vm._s(_vm.device.os.name)+" "+_vm._s(_vm.device.os.version))]),_c('h5',[_vm._v("Browser: "+_vm._s(_vm.device.browser.name)+" "+_vm._s(_vm.device.browser.version))])]),_c('AppAutoupdate',{attrs:{"onlyShowCopyright":true}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n        jumbotron\n        d-flex\n        flex-row\n        justify-content-center\n        align-items-center\n      ",staticStyle:{"height":"50vh","color":"black"}},[_c('h1',[_vm._v(" Please use "),_c('strong',[_vm._v("Google Chrome")]),_c('br'),_vm._v("to access this app. ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n        jumbotron\n        d-flex\n        flex-row\n        justify-content-center\n        align-items-center\n      ",staticStyle:{"height":"50vh","color":"black"}},[_c('h1',[_vm._v("Please use Safari")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n        jumbotron\n        d-flex\n        flex-row\n        justify-content-center\n        align-items-center\n      ",staticStyle:{"height":"50vh","color":"black"}},[_c('h1',[_vm._v("Please use Safari")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n        jumbotron\n        d-flex\n        flex-row\n        justify-content-center\n        align-items-center\n      ",staticStyle:{"height":"50vh","color":"black"}},[_c('h1',[_vm._v(" Please use "),_c('strong',[_vm._v("Google Chrome")]),_c('br'),_vm._v("to access this app. ")])])}]

export { render, staticRenderFns }