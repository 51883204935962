<template>
  <div class="mx-4">
    <div class="d-flex justify-content-between">
        <h3>
          Click the <img src="./InstallationShare.svg" style="width:1em; height:1.5em;">
        icon at top of screen   <img src="./InstallationArrow.svg" style="width:3em;height:2em;">
  
        </h3>
     </div>

    <div>
      <b-button v-b-toggle.collapse-browser-menu variant="link" class="p-0"
        >I don't have this icon</b-button
      >
      <b-collapse id="collapse-browser-menu">
        <b-card style="max-width: 40em">
          <p>
            <ol>
              <li>Go to Settings &gt; General. If an iOS update is available, install it.</li>
              <br />
                 <li>
                Tell the app team your problem is "<code
                  >No 'share' icon in {{ device.browser.name }}
                  {{ device.browser.version }} on {{ device.os.name }}
                  {{ device.os.version }}</code
                >".
              </li>
            </ol>
          </p>
          <b-button v-b-toggle.collapse-browser-menu variant="light"
            >Close</b-button
          >
        </b-card>
      </b-collapse>
    </div>

    <br />
    <br />
    
    <h3>Choose "Add to Home Screen"</h3>
    <div>
      <b-button
        v-b-toggle.collapse-chrome-install-or-open
        variant="link"
        class="p-0" style="text-align:left"
        >My <img src="./InstallationShare.svg" style="width:1em; height:1.5em;"> has no "Add to Home Screen"</b-button
      >
      <b-collapse id="collapse-chrome-install-or-open">
        <b-card style="max-width: 40em">
                      <ul>
              <li>Go to Settings &gt; General. If an OS update is available, install it.</li>
               <br />

              <li>
                        Tell the app team your problem is "<code
                  >No 'Add to Home Screen' option in 'Share' menu of {{ device.browser.name }}
                  {{ device.browser.version }} on {{ device.os.name }}
                  {{ device.os.version }}</code
                >".
 
              </li>
              <br />
            </ul>

          <b-button v-b-toggle.collapse-chrome-install-or-open variant="light"
            >Close</b-button
          >
        </b-card>
      </b-collapse>
    </div>
    <br />
    <br />
  </div>
</template>

<script>
export default {
  props: {
    device: { type: Object, required: true },
    deviceToPromptInstallation: { type: String, required: true },
  },
};
</script>

<style>
</style>