// Idea for a separate firebase.js file came from here:
// https://www.youtube.com/watch?v=831zOI02Q_0

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";

var config = {
  apiKey: "AIzaSyBqHUPTIADRqKIsvYKWv54QM188ZBO7dow",
  authDomain: "scantensus.firebaseapp.com",
  databaseURL: "https://scantensus.firebaseio.com",
  projectId: "scantensus",
  storageBucket: "scantensus.appspot.com",
  messagingSenderId: "1062965192746",
};
const firebaseApp = firebase.initializeApp(config);

export const COMPARE_API_URL =
  "https://europe-west2-imagine-d6819.cloudfunctions.net/scantensus_compare";

// AUTH
// https://firebase.google.com/docs/auth/web/google-signin
export const AUTH_PERSISTENCE_SESSION = firebase.auth.Auth.Persistence.SESSION;
export const auth = firebaseApp.auth();
export const provider = new firebase.auth.GoogleAuthProvider();

// DATABASE
const db = firebaseApp.database(); // Don't allow components to access this directly. They must do using one of the following.

export function dbRefFromURL(x) {
  // Enabling firebase refs having been passed as a string (and therefore stored in Vue router's "/:param" format), to be reconstituted into a Ref.
  return db.refFromURL(x);
}

// Globals
export const amOnlineRef = db.ref(".info/connected");
export const systemStatusRef = db.ref("systemStatus");
export const fiducialRef = db.ref("fiducial");
export const projectsSummaryCacheRef = db.ref("projectsSummaryCache");
export const SYSTEM_REFERENCE_PROJECT_ID = "system-reference";

// These roots then have branches per user
export const contactRootRef = db.ref("userContact");
export const presenceRootRef = db.ref("userPresence");
export const privilegesRootRef = db.ref("userPrivileges");

// Log
export const logRootRef = db.ref("log");

// CONSTANTS

// General constants
export const ServerValueTIMESTAMP = firebase.database.ServerValue.TIMESTAMP;

//   _     _   _              ____      _ _       _     _
//  | |__ | |_| |_ _ __  ___ / ___|__ _| | | __ _| |__ | | ___
//  | '_ \| __| __| '_ \/ __| |   / _` | | |/ _` | '_ \| |/ _ \
//  | | | | |_| |_| |_) \__ \ |__| (_| | | | (_| | |_) | |  __/
//  |_| |_|\__|\__| .__/|___/\____\__,_|_|_|\__,_|_.__/|_|\___|
//                |_|
export const saveOpinion = firebase.functions().httpsCallable("saveOpinion");
export const readProjectManagementByProjectFromFirebase = firebase
  .functions()
  .httpsCallable("readProjectManagementByProjectFromFirebase");
export const updateProjectsList = firebase
  .functions()
  .httpsCallable("updateProjectsList"); // This is now done by python at upload-new-images time.
export const getDisplayNames = firebase
  .functions()
  .httpsCallable("getDisplayNames");
export const archiveProject = firebase
  .functions()
  .httpsCallable("archiveProject");
