<template>
  <div>
    <!-- During debugging, we have a second instance of the "AppAutoupdate" component, just to show the status at the bottom of home pages. It doesn't produce a yellow warning but (as it so happens) it does cause its own auto-updates -->

    <div
      v-if="onlyShowCopyright"
      class
      style="text-align: center; font-size: 50%; color: darkgray"
    >
      (C) UnityImaging.net Team 2017-2024. Status code
      {{ (runningVersion || "").slice(12).replace(/:/g, "") }}&nbsp;:&nbsp;{{
        (latestVersion || "").slice(12).replace(/:/g, "")
      }}
    </div>

    <div v-if="!onlyShowCopyright">
      <b-alert
        show
        v-if="runningVersionRelative() > 0"
        variant="primary"
        style="
          z-index: 3000;
          position: fixed;
          top: 0;
          right: 25%;
          opacity: 0.5;
          width: 50%;
        "
        class="px-4 py-1"
      >
        <h6 style="text-align: center">Being built and deployed...</h6>
      </b-alert>

      <b-alert
        show
        v-if="runningVersionRelative() < 0"
        variant="warning"
        @click="getNewest"
        style="
          z-index: 9000;
          position: fixed;
          top: 0;
          left: 0;
          opacity: 0.85;
          width: 100%;
        "
        class="px-4 py-1"
      >
        <!-- {{runningVersion}}
      <br>
        {{latestVersion}}-->
        <div v-if="aboutToAutoUpdateNow">
          <br />
          <br />
          <h6 style="text-align: center">Updating...</h6>
          <br />
          <br />
        </div>
        <div v-else>
          <h5 style="text-align: center">
            App will auto-update shortly.
            <span v-if="showUpdateButton">
              <b-button
                variant="primary"
                style="pointer-events: auto; z-index: 9999"
                @click="getNewest"
                >Update immediately</b-button
              >.
            </span>
          </h5>
        </div>
      </b-alert>
    </div>
  </div>
</template>

<script>
import { BUILDVERSION } from "./ext/buildVersion.js"; // This is overwritten by the building process

import {
  systemStatusRef, // just in the global space, non- "this."
} from "../utils/firebase.js";

import { getNewest } from "../utils/getNewest.js"; // but remember to also re-export it in methods

export default {
  props: {
    onlyShowCopyright: { type: Boolean, required: false, default: false },
  },
  name: "AppAutoupdate",

  data: function () {
    return {
      aboutToAutoUpdateNow: false, // Will write to this once have $ROUTE and LATESTVERSION
      runningVersion: BUILDVERSION,
      latestVersion: "",
      latestVersionRead: false,
      showUpdateButton: true,
    };
  },

  watch: {
    $route: function (routeObj) {
      if (routeObj.nonExistentProperty) {
        // Do nothing, but prevent the linter complaining we are not using routeObj
      }
      this.makeAutoUpdateDecision();
    },
  },

  created() {
    systemStatusRef.child("latestVersion").on("value", snapshot => {
      this.latestVersion = snapshot.val() || null;
      this.latestVersionRead = true;
      this.makeAutoUpdateDecision();
    });
  },

  methods: {
    runningVersionRelative: function () {
      if (this.latestVersionRead) {
        if (this.runningVersion > this.latestVersion) {
          return +1; // The version running is NEWER than the one listed on firebase, which will happen if you have half-run the build script and then aborted it or (while it runs) are serving it locally via NPM RUN DEV.
        } else if (this.runningVersion < this.latestVersion) {
          return -1; // The version running is OLDER than the one listed on firebase, which means you should update
        } else {
          return 0; // You are up to date
        }
      }
      return undefined;
    },

    makeAutoUpdateDecision() {
      if (this.runningVersionRelative() < 0) {
        if (
          typeof this.$route.meta.gracePeriodMinutesBeforeAutoUpdate ===
          "number"
        ) {
          this.waitGraceMinutesAndThenAutoUpdateIfOnSameRouteName(
            this.$route.name,
            this.$route.meta.gracePeriodMinutesBeforeAutoUpdate
          );
        }
      }
    },

    waitGraceMinutesAndThenAutoUpdateIfOnSameRouteName(
      routeNameAtStartOfGracePeriod,
      gracePeriodMinutes
    ) {
      if (routeNameAtStartOfGracePeriod) {
        setTimeout(() => {
          if (this.$route.name === routeNameAtStartOfGracePeriod) {
            this.warnAndDoAutoUpdate();
          }
        }, gracePeriodMinutes * 60 * 1000);
      }
    },

    warnAndDoAutoUpdate() {
      this.aboutToAutoUpdateNow = true;
      setTimeout(getNewest, 2000);
    },

    //
    getNewest() {
      // Copy from GLOBAL (imported area) to THIS, so the TEMPLATE can use this in @click
      this.showUpdateButton = false;
      getNewest();
    },
  },
};
</script>

<style scoped></style>
