<template>
  <div style="color: black">
    <div
      v-if="!showMaxPrivacy && !showNotWorking"
      style="height: 100vh; width: 100%"
      class="d-flex flex-column align-items-center justify-content-center"
    >
      <h1>UnityImaging.Net</h1>
      <br />
      <div class="d-flex flex-column">
        <div class="d-flex flex-row">
          <div class="mr-4">
            <img src="../assets/logo.png" style="max-width: 4.5em" alt="logo" />
          </div>
          <h4>
            Clinically-led AI for<br />
            <span style="font-size: 115%">Medical Imaging</span>
          </h4>
        </div>
        <br />
        <br />

        <b-button @click="socialLogin" variant="light">
          Please sign in
          <br />via Google
          <br />
          <img
            alt="Google Logo"
            src="../assets/google_logo.svg"
            height="80"
            width="120"
          />
        </b-button>
      </div>

      <br />
      <code>{{ error.code }}</code>
      <p>{{ error.message }}</p>
      <br />

      <b-button
        v-if="!showNotWorking"
        size="sm"
        variant="link"
        style="color: blue"
        class="px-2"
        @click="showNotWorking = true"
        >Sign-in not working?</b-button
      >
      <br />
      <b-button
        size="sm"
        variant="link"
        style="color: blue"
        class="px-2"
        @click="showMaxPrivacy = true"
        >Why do I have to sign in?</b-button
      >
    </div>

    <div v-if="showNotWorking">
      <LoginNotWorking @ok="showNotWorking = false"></LoginNotWorking>
    </div>

    <div v-if="showMaxPrivacy">
      <LoginMaxPrivacy @ok="showMaxPrivacy = false"></LoginMaxPrivacy>
    </div>
  </div>
</template>

<script>
import LoginMaxPrivacy from "@/views/LoginMaxPrivacy.vue";
import LoginNotWorking from "@/views/LoginNotWorking.vue";

import { AUTH_PERSISTENCE_SESSION, auth, provider } from "../utils/firebase";

export default {
  name: "Login",
  components: { LoginNotWorking, LoginMaxPrivacy },
  data() {
    return {
      showNotWorking: false,
      showMaxPrivacy: false,
      error: { code: "", message: "" },
    };
  },
  methods: {
    socialLogin() {
      auth
        .setPersistence(AUTH_PERSISTENCE_SESSION)
        .then(() => {
          return auth.signInWithPopup(provider); // the signInWithPopup doesn't work because going to a new page and some sort of security blockade put up by either Google Auth, or Apple, or something. https://github.com/firebase/firebaseui-web/issues/522
        })
        .catch(error => {
          this.error = error;
        });
    },
  },
};
</script>

<style></style>
